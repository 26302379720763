import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import RichText from 'components/RichText'

import letterSpacingFor from 'utils/letterSpacingFor'

export default class RichTextElement extends PureComponent {
  static propTypes = exact({
    children: PropTypes.node,
    element: PropTypes.object.isRequired,
    fontFamily: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
  })

  elementStyle() {
    const { element, fontFamily } = this.props
    const {
      anchorColor,
      color,
      fontSize,
      lineHeight,
      height,
      markerColor,
      textAlign,
      textCapitalisation,
      width,
    } = element
    const letterSpacing = letterSpacingFor(fontSize)
    const textTransform = textCapitalisation == 'lower' ? 'lowercase' : 'none'

    return {
      color,
      fontFamily,
      fontKerning: 'normal',
      fontSize,
      height,
      lineHeight: `${lineHeight}px`,
      letterSpacing: `${letterSpacing}em`,
      textAlign,
      textTransform,
      width,
      '--marker-color': markerColor,
      '--anchor-color': anchorColor,
    }
  }

  render() {
    const { children, element, isActive } = this.props
    const style = this.elementStyle()

    return (
      <div
        className="RichTextElement"
        style={style}
        data-element-id={element.id}
      >
        {isActive ? children : <RichText element={element} />}
      </div>
    )
  }
}
