import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Dropdown } from 'semantic-ui-react'

import apiRequest from 'utils/apiRequest'
import { dropdownNavigationEvent, sendToDataLayer } from 'utils/dataLayer'

class CardControls extends PureComponent {
  static propTypes = exact({
    direction: PropTypes.string,
    menuIsUpward: PropTypes.bool,
    menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  })

  static defaultProps = {
    direction: 'right',
    menuIsUpward: false,
  }

  handleClick = (event, data) => {
    const { confirmation, method, path, payload, text } = data

    if (confirmation && !confirm(confirmation)) {
      return
    }

    const lowerMethod = method ? method.toLowerCase() : 'get'

    sendToDataLayer(dropdownNavigationEvent(text.toLocaleLowerCase(), path))

    if (lowerMethod === 'get') {
      window.location = path
    } else if (lowerMethod === 'delete') {
      apiRequest({
        method: 'delete',
        url: path,
        payload: { _method: 'delete' },
        requestDescription: `actioning... ${text}`,
      })
    } else {
      apiRequest({
        method: lowerMethod,
        url: path,
        payload: payload || {},
        requestDescription: `actioning... ${text}`,
      })

      // After an update, we need to reload to get updated props data.
      if (lowerMethod == 'put') {
        window.location.reload()
      }
    }
  }

  render() {
    const { direction, menuIsUpward, menuLinks } = this.props

    const menuItems = menuLinks.map((menuLink) => (
      <Dropdown.Item
        key={menuLink.text}
        icon={menuLink.icon}
        onClick={this.handleClick}
        text={menuLink.text}
        method={menuLink.method}
        path={menuLink.path}
        confirmation={menuLink.confirmation}
        payload={menuLink.payload}
      />
    ))

    return (
      <div className="CardControls">
        <Dropdown
          icon="ellipsis vertical"
          aria-label="actions"
          name="actions"
          upward={menuIsUpward}
          direction={direction}
        >
          <Dropdown.Menu>{menuItems}</Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
}

export default CardControls
