import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import DocCard from 'components/DocCard'

import rowFittedSizes from 'utils/rowFittedSizes'

const toSize = ({ markup }) => {
  return { width: markup.pageWidth, height: markup.pageHeight }
}

const DocCardRow = ({ cardsInRow, cardsData, fontFamily, spacing }) => {
  const [rowWidth, setRowWidth] = useState(10000)
  const rowRef = useRef(null)

  useEffect(() => {
    const updateRowWidth = () => {
      setRowWidth(rowRef.current.getBoundingClientRect().width)
    }

    updateRowWidth()

    window.addEventListener('resize', updateRowWidth)

    return () => {
      window.removeEventListener('resize', updateRowWidth)
    }
  }, [])

  const sizes = rowFittedSizes(
    cardsData.map(toSize),
    rowWidth,
    spacing,
    cardsInRow
  )

  const docCards = cardsData.map((cardData, index) => {
    const { height, scaleFactor, width } = sizes[index]

    const { key, ...docCardData } = cardData

    return (
      <div
        className="DocCardRow__card"
        key={key}
        style={{
          marginLeft: index && spacing,
        }}
      >
        <DocCard
          {...docCardData}
          fontFamily={fontFamily}
          height={height}
          scale={scaleFactor}
          width={width}
        />
      </div>
    )
  })

  return (
    <div className="DocCardRow" ref={rowRef}>
      {docCards}
    </div>
  )
}

DocCardRow.propTypes = exact({
  cardsInRow: PropTypes.number.isRequired,
  cardsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  fontFamily: PropTypes.string.isRequired,
  spacing: PropTypes.number.isRequired,
})

export default DocCardRow
