function ApiError(messages) {
  this.messages = messages
}

const csrf_token = () => {
  const csrf_token_element = document.querySelector('meta[name="csrf-token"]')
  return csrf_token_element ? csrf_token_element.content : null
}

const apiRequest = async ({
  method,
  url,
  payload,
  requestDescription,
  reloadOnAuthError,
}) => {
  reloadOnAuthError ??= true

  try {
    const response = await fetch(request(method, url, payload))

    if (response.redirected) {
      window.location = response.url
    } else if (response.status === 401 || response.status === 403) {
      // `reload(true)` causes the page to always be reloaded from the server,
      // rather than from browser cache
      // Reloading page from server triggers reauthentication if necessary
      if (reloadOnAuthError) {
        window.location.reload(true)
      } else {
        return {
          ok: response.ok,
          status: response.status,
          body: {},
        }
      }
    } else {
      let jsonBody = {}

      try {
        jsonBody = await response.json()
      } catch (error) {
        // No-op.
      }

      return {
        ok: response.ok,
        status: response.status,
        body: jsonBody,
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(error)
      throw new Error(
        `There was a problem while ${requestDescription}. Are you connected to the internet?`
      )
    }
  }
}

const request = (method, url, payload) => {
  const headers = new Headers()
  headers.set('X-CSRF-Token', csrf_token())
  headers.set('Content-Type', 'application/json')

  const options = {
    method: method,
    headers: headers,
    credentials: 'same-origin',
    redirect: 'follow',
  }

  const body =
    method.toLowerCase() === 'get' || method.toLowerCase() === 'head'
      ? {}
      : {
          body: JSON.stringify(payload),
        }

  return new Request(url, { ...options, ...body })
}

export { ApiError }

export default apiRequest
