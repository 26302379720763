import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

export default class ImageElement extends PureComponent {
  static propTypes = exact({
    element: PropTypes.object.isRequired,
    onLoad: PropTypes.func,
    preview: PropTypes.bool,
  })

  render() {
    const { element, preview, ...rest } = this.props
    const { width, height } = element

    const imageWidth = width / element.cropFractions.width,
      imageHeight = height / element.cropFractions.height

    const left = -element.cropFractions.left * imageWidth
    const top = -element.cropFractions.top * imageHeight
    const transform = `scaleX(${element.isFlipped ? -1 : 1})`

    const imageFilter = element.filterUrl && (
      <img
        src={element.filterUrl}
        style={{
          position: 'absolute',
          left: left,
          top: top,
          width: imageWidth,
          height: imageHeight,
          transform: transform,
        }}
        draggable="false"
      />
    )

    const outline = element.backgroundColor
      ? '1px solid ' + element.backgroundColor
      : 'none'

    const previewImageSrc = () => {
      const url = new URL(element.url, window.location.origin)
      url.searchParams.set('variant', 'preview_thumbnail')
      return url.toString()
    }

    const imageSrc = preview ? previewImageSrc() : element.url

    return (
      <div
        className="ImageElement"
        data-element-id={element.id}
        style={{
          width,
          height,
          backgroundColor: element.backgroundColor,
          borderRadius: element.borderRadius,
          outline: outline,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <img
          src={imageSrc}
          style={{
            position: 'absolute',
            left: left,
            top: top,
            width: imageWidth,
            height: imageHeight,
            transform: transform,
          }}
          draggable="false"
          {...rest}
        />

        {imageFilter}
      </div>
    )
  }
}
