import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'

import { styleForColorOrGradient } from 'utils/colorHelpers'
import letterSpacingFor from 'utils/letterSpacingFor'
import { withSingleWord } from 'utils/slateHelpers'

const GradientTextElement = ({
  isActive,
  element,
  pageId,
  onUpdateElement,
}) => {
  const toEditorValue = (text) => [
    {
      type: 'text',
      children: [{ text }],
    },
  ]
  const [editor] = useState(() => withSingleWord(withReact(createEditor())))

  const handleChange = (newValue) => {
    // Ignore selection changes
    if (editor.operations.every((op) => op.type === 'set_selection')) {
      return
    }

    const text = newValue[0].children[0].text

    onUpdateElement(element.id, pageId, { text })
  }

  const renderLeaf = useCallback(
    (leafProps) => {
      const style = {
        color: '#888888', // set the cursor to a usable color
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        ...styleForColorOrGradient(element.color),
      }

      return (
        <span style={style} {...leafProps.attributes}>
          {leafProps.children}
        </span>
      )
    },
    [element.color]
  )

  const editorStyle = {
    fontSize: `${element.fontSize}px`,
    fontFamily: 'Graphik',
    letterSpacing: `${letterSpacingFor(element.fontSize)}em`,
    lineHeight: '1em',
  }

  const style = {
    width: element.width,
    height: element.height,
    ...(isActive && editorStyle),
  }

  return (
    <div
      className="GradientTextElement"
      data-element-id={element.id}
      style={style}
    >
      {isActive ? (
        <Slate
          editor={editor}
          initialValue={toEditorValue(element.text)}
          onChange={handleChange}
        >
          <Editable
            style={{ whiteSpace: 'pre', outline: 'none' }}
            renderLeaf={renderLeaf}
          />
        </Slate>
      ) : (
        <div
          className="GradientTextElement__container"
          dangerouslySetInnerHTML={{ __html: element.renderedSvg }}
        />
      )}
    </div>
  )
}

GradientTextElement.propTypes = exact({
  isActive: PropTypes.bool,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    renderedSvg: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  pageId: PropTypes.string.isRequired,
  onUpdateElement: PropTypes.func.isRequired,
})

export default GradientTextElement
