import apiRequest from 'utils/apiRequest'

import { UPDATE_DOC } from 'EditorApp/actionTypes'

export function updateDoc(attrs) {
  return { type: UPDATE_DOC.action, attrs }
}

export function attemptCreationExport(href, onExportComplete, attemptNum = 1) {
  if (attemptNum > 8) {
    onExportComplete()
    return
  }

  apiRequest({
    method: 'GET',
    url: href,
    requestDescription: 'exporting the file',
  })
    .then((response) => {
      if (response.status !== 404) {
        onExportComplete()
        return
      }

      const retryDelay = Math.min(attemptNum * 2 * 1000, 10000)
      setTimeout(
        () => attemptCreationExport(href, onExportComplete, attemptNum + 1),
        retryDelay
      )
    })
    .catch(() => {
      onExportComplete()
    })
}
