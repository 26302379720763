import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import StyleguideViolationWarning from './StyleguideViolationWarning'

const flattenChunks = (chunks) => {
  return chunks.map(({ text }) => text).join(' ')
}

const CharacterLengthWarning = ({ maxCharacters, minCharacters, chunks }) => {
  const text = flattenChunks(chunks)
  const visibleCharacters = text.replace(/\s+/g, '')

  let warningMessage = null
  if (visibleCharacters.length > maxCharacters) {
    warningMessage = `maximum ${maxCharacters} characters is recommended`
  } else if (visibleCharacters.length < minCharacters) {
    warningMessage = `minimum ${minCharacters} characters is recommended`
  }

  return <StyleguideViolationWarning message={warningMessage} />
}

CharacterLengthWarning.propTypes = exact({
  maxCharacters: PropTypes.number.isRequired,
  minCharacters: PropTypes.number.isRequired,
  chunks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
})

export default CharacterLengthWarning
