const findLastIndex = (array, matcherFn) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const element = array[i]
    if (matcherFn(element)) {
      return i
    }
  }
  return -1
}

const singularize = (word) => word.replace(/s$/, '')

export function dropdownNavigationEvent(eventType, path) {
  const pathComponents = path.split('/')
  const idComponentIndex = findLastIndex(
    pathComponents,
    (component) => component && !isNaN(component)
  )

  const modelId = Number(pathComponents[idComponentIndex])
  const modelNamePlural = String(pathComponents[idComponentIndex - 1])

  return {
    eventType,
    event: modelNamePlural,
    [`${singularize(modelNamePlural)}ID`]: modelId,
  }
}

export function dropdownFilterEvent(eventType, id) {
  return {
    eventType,
    event: 'filter',
    [`${eventType}ID`]: id,
  }
}

export function sendToDataLayer(event) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(event)
}
