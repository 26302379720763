import React, { useState } from 'react'
import { Dimmer, Loader, Header, Icon } from 'semantic-ui-react'

import { attemptCreationExport } from 'EditorApp/actions/doc'
import useTimeout from '../hooks/useTimeout'

const ExportPage = () => {
  const [isExporting, setIsExporting] = useState(true)

  const onExportComplete = () => {
    setIsExporting(false)
  }

  useTimeout(() => {
    if (isExporting) {
      attemptCreationExport(window.location.href, onExportComplete)
    }
  }, 2000)

  return (
    <Dimmer active={true} page>
      {isExporting ? (
        <Loader indeterminate>Preparing File</Loader>
      ) : (
        <Header as="h2" icon inverted>
          <Icon name="cloud download" /> Export complete
        </Header>
      )}
    </Dimmer>
  )
}

export default ExportPage
