import React, { useState, useCallback, useReducer, useMemo } from 'react'
import PropTypes from 'prop-types'
import createPageSizesReducer, {
  createPageSizesDefaultState,
} from '../reducers/createPageSizes'
import {
  toggleUnits,
  setPlatform,
  setCategory,
  setSubcategory,
  resetFields,
  updateWidthField,
  updateHeightField,
} from '../actions/createPageSizes'
import { getOptionsFromCategoryTree } from 'utils/categoryHelpers'
import { convertUnits, UNIT_PX } from 'utils/unitHelpers'
import TableDropdown from 'components/TableDropdown'
import exact from 'prop-types-exact'

const resetForm = () => {
  document.getElementById('.create-page-size-form')?.reset()
}

const CreatePageSize = ({ categoryTree, pageSizes }) => {
  const [editingRow, setEditingRow] = useState(false)
  const [
    {
      selectedPlatform,
      selectedCategory,
      selectedSubcategory,
      units,
      heightFieldValue,
      widthFieldValue,
    },
    dispatch,
  ] = useReducer(createPageSizesReducer, createPageSizesDefaultState)

  const handleEditNewRow = useCallback((ev) => {
    ev.preventDefault()
    setEditingRow(true)
  }, [])

  const handleCancelEditNewRow = useCallback(() => {
    setEditingRow(false)
    resetForm()
    dispatch(resetFields())
  }, [])

  const platforms = useMemo(
    () => getOptionsFromCategoryTree(categoryTree),
    [categoryTree]
  )

  const categories = useMemo(
    () =>
      selectedPlatform
        ? getOptionsFromCategoryTree(categoryTree, [parseInt(selectedPlatform)])
        : [],
    [categoryTree, selectedPlatform]
  )

  const subcategories = useMemo(
    () =>
      selectedPlatform && selectedCategory
        ? getOptionsFromCategoryTree(categoryTree, [
            parseInt(selectedPlatform),
            parseInt(selectedCategory),
          ])
        : [],
    [categoryTree, selectedPlatform, selectedCategory]
  )

  return (
    <div className="CreatePageSize">
      <table className="ui compact celled table small left aligned fixed">
        <thead>
          <tr>
            <th>platform</th>
            <th>category</th>
            <th>subcategory</th>
            <th>name</th>
            <th>
              <div className="CreatePageSize__units-toggle">
                <span>width </span>
                <button
                  aria-label={`${units} (toggle units between pixels, milimetres, inches)`}
                  className="UnitsToggle"
                  onClick={() => dispatch(toggleUnits())}
                >
                  {units}
                </button>
              </div>
            </th>
            <th>
              <div className="CreatePageSize__units-toggle">
                <span>height </span>
                <button
                  aria-label={`${units} (toggle units between pixels, milimetres, inches)`}
                  className="UnitsToggle"
                  onClick={() => dispatch(toggleUnits())}
                >
                  {units}
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {pageSizes.map(
            ({ id, platform, category, subcategory, label, width, height }) => (
              <tr key={id}>
                <td>{platform}</td>
                <td>{category}</td>
                <td>{subcategory}</td>
                <td>{label}</td>
                <td>{convertUnits(width, UNIT_PX, units)}</td>
                <td>{convertUnits(height, UNIT_PX, units)}</td>
              </tr>
            )
          )}
          {editingRow && (
            <tr>
              <td className="CreatePageSize__editable-row">
                <TableDropdown
                  placeholder="platform"
                  aria-label="platform"
                  options={platforms}
                  onChange={(ev) => dispatch(setPlatform(ev.target.value))}
                  selectedValue={selectedPlatform}
                  form="create-page-size-form"
                  required
                />
              </td>
              <td className="CreatePageSize__editable-row">
                <TableDropdown
                  placeholder="category"
                  aria-label="category"
                  options={categories}
                  onChange={(ev) => dispatch(setCategory(ev.target.value))}
                  selectedValue={selectedCategory}
                  form="create-page-size-form"
                  required
                />
              </td>
              <td className="CreatePageSize__editable-row">
                <TableDropdown
                  placeholder="subcategory"
                  aria-label="subcategory"
                  options={subcategories}
                  onChange={(ev) => dispatch(setSubcategory(ev.target.value))}
                  selectedValue={selectedSubcategory}
                  form="create-page-size-form"
                  name="page_size[category_id]"
                  id="page_size_category_id"
                  required
                />
              </td>
              <td className="CreatePageSize__editable-row">
                <input
                  aria-label="name"
                  type="text"
                  className="CreatePageSize__input"
                  placeholder="name"
                  form="create-page-size-form"
                  name="page_size[name]"
                  id="page_size_name"
                  required
                />
              </td>
              <td className="CreatePageSize__editable-row">
                <div className="CreatePageSize__units-container">
                  <input
                    aria-label={`width (${units})`}
                    type="number"
                    className="CreatePageSize__input"
                    placeholder="width"
                    min="0"
                    required
                    onChange={(ev) =>
                      dispatch(updateWidthField(ev.target.value))
                    }
                  />
                  <input
                    type="hidden"
                    form="create-page-size-form"
                    name="page_size[width]"
                    id="page_size_width"
                    value={convertUnits(widthFieldValue, units, UNIT_PX)}
                  />
                  <button
                    aria-label={`${units} (toggle units between pixels, milimetres, inches)`}
                    className="UnitsToggle"
                    onClick={() => dispatch(toggleUnits())}
                  >
                    {units}
                  </button>
                </div>
              </td>
              <td className="CreatePageSize__editable-row">
                <div className="CreatePageSize__units-container">
                  <input
                    aria-label={`height (${units})`}
                    type="number"
                    className="CreatePageSize__input"
                    placeholder="height"
                    min="0"
                    required
                    onChange={(ev) =>
                      dispatch(updateHeightField(ev.target.value))
                    }
                  />
                  <input
                    type="hidden"
                    form="create-page-size-form"
                    name="page_size[height]"
                    id="page_size_height"
                    value={convertUnits(heightFieldValue, units, UNIT_PX)}
                  />
                  <button
                    aria-label={`${units} (toggle units between pixels, milimetres, inches)`}
                    className="UnitsToggle"
                    onClick={() => dispatch(toggleUnits())}
                  >
                    {units}
                  </button>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="actions">
        {editingRow ? (
          <>
            <button
              type="submit"
              className="ui button basic"
              form="create-page-size-form"
            >
              confirm new page size
            </button>
            <button
              type="button"
              onClick={handleCancelEditNewRow}
              className="ui button basic"
            >
              cancel
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={handleEditNewRow}
            className="ui button basic"
          >
            add new page size
          </button>
        )}
      </div>
    </div>
  )
}

CreatePageSize.propTypes = exact({
  categoryTree: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.array,
    })
  ),
  pageSizes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired,
      height: PropTypes.string.isRequired,
      platform: PropTypes.string,
      category: PropTypes.string,
      subcategory: PropTypes.string,
    })
  ),
})

export default CreatePageSize
