import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import StyleguideViolationWarning from './StyleguideViolationWarning'

const ElementTooSmallWarning = ({ minimumSize, actualSize }) => {
  if (
    actualSize.width >= minimumSize.width &&
    actualSize.height >= minimumSize.height
  ) {
    return null
  }

  const message = 'element is too small, please increase size'
  return <StyleguideViolationWarning message={message} />
}

ElementTooSmallWarning.propTypes = exact({
  minimumSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  actualSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
})

export default ElementTooSmallWarning
