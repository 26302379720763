// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'components' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/packs/components'

import * as SemanticComponents from 'semantic-ui-react'

import mount from 'mount'

import CreatePageSize from 'AdminApp/containers/CreatePageSize'
import CreateTemplate from 'AdminApp/containers/CreateTemplate'
import ExportMenu from 'components/ExportMenu'
import ExportPage from 'components/ExportPage'
import DocCardRow from 'components/DocCardRow'
import DocShow from 'components/DocShow'
import EditSections from 'AdminApp/containers/EditSections'
import FlashMessage from 'components/FlashMessage'
import IndexFilterer from 'components/IndexFilterer'
import NameableFormSelect from 'components/NameableFormSelect'
import Navbar from 'components/Navbar'
import NavbarLinksMenu from 'components/NavbarLinksMenu'
import PrintOptionAndQuantitySelect from 'components/PrintOptionAndQuantitySelect'
import SearchForm from 'components/SearchForm'
import DivisionSelector from 'components/DivisionSelector'
import BusinessLineSelector from 'components/BusinessLineSelector'
import BusinessLineRoleSelector from 'components/BusinessLineRoleSelector'

mount({
  CreatePageSize,
  CreateTemplate,
  ExportMenu,
  ExportPage,
  BusinessLineSelector,
  BusinessLineRoleSelector,
  DivisionSelector,
  DocCardRow,
  DocShow,
  EditSections,
  FlashMessage,
  IndexFilterer,
  NameableFormSelect,
  Navbar,
  NavbarLinksMenu,
  PrintOptionAndQuantitySelect,
  SearchForm,
  ...SemanticComponents,
})
