import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

class CopyToClipboardButton extends PureComponent {
  static propTypes = exact({
    link: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    setSelectedLink: PropTypes.func.isRequired,
  })

  constructor(props) {
    super(props)
    this.copyableLink = React.createRef()
  }

  copyToClipboard = () => {
    const copiedLinkAddress = this.copyableLink.current

    // create a range
    const range = document.createRange()
    range.selectNodeContents(copiedLinkAddress)

    // select and copy the range contents to the clipboard
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
  }

  handleClick = () => {
    this.copyToClipboard(this.props.link)
    const { index, setSelectedLink } = this.props
    setSelectedLink(index)
  }

  render() {
    const { link, selected } = this.props

    return (
      <a
        onClick={this.handleClick}
        className={classnames({
          CopyToClipboardButton: true,
          CopyToClipboardButton__copied: selected,
        })}
      >
        <div
          ref={this.copyableLink}
          style={{
            height: 0,
            left: -1,
            margin: 0,
            opacity: 0,
            position: 'fixed',
            top: -1,
            width: 0,
          }}
        >
          {link}
        </div>
        {selected ? 'copied' : 'copy link'}
      </a>
    )
  }
}

export default CopyToClipboardButton
