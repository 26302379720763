const tiers = ['platform', 'category', 'subcategory']

// get category dropdown options based on parameters passed
export const getOptionsFromCategoryTree = (
  categoryTree,
  ids,
  { tier = 0, convertFn = convertToOptions } = {}
) => {
  if (!Array.isArray(categoryTree)) {
    console.error('categoryTree is invalid')
    return null
  }

  if (categoryTree.length === 0) {
    return null
  }

  if (!Array.isArray(ids) || ids.length === 0) {
    return convertFn(categoryTree)
  }

  const [tierId] = ids

  const categories = categoryTree.find(({ id }) => tierId === id)?.children

  if (!categories) {
    console.error(`Could not find categories with ${tiers[tier]} ID: ${tierId}`)
    return null
  }

  return getOptionsFromCategoryTree(categories, ids.slice(1), {
    tier: tier + 1,
    convertFn,
  })
}

export const convertToOptions = (arr) =>
  arr?.map(({ name, id }) => ({
    key: id,
    text: name,
    value: id,
  })) || []
