import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const ButtonElement = ({ element }) => {
  return (
    <div className="ButtonElement" data-element-id={element.id}>
      <div
        className="ButtonElement__container"
        dangerouslySetInnerHTML={{ __html: element.renderedSvg }}
      />
    </div>
  )
}

ButtonElement.propTypes = exact({
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    renderedSvg: PropTypes.string.isRequired,
  }),
})

export default ButtonElement
