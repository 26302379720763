import React from 'react'
import { Dropdown } from 'semantic-ui-react'

interface MenuLinkItem {
  text: string
  url: string
  active: boolean
}

interface MenuSectionItem {
  text: string
  items: MenuItem[]
}

type MenuItem = MenuLinkItem | MenuSectionItem

const NavbarLinksMenu = (item: MenuSectionItem) => {
  const renderMenu = (item: MenuSectionItem) => {
    const itemElements = item.items.map((item, index) => {
      if ('items' in item) {
        return <Dropdown.Item key={index}>{renderMenu(item)}</Dropdown.Item>
      } else {
        return (
          <Dropdown.Item
            key={index}
            text={item.text}
            as="a"
            href={item.url}
            selected={item.active}
          />
        )
      }
    })

    return (
      <Dropdown text={item.text}>
        <Dropdown.Menu>{itemElements}</Dropdown.Menu>
      </Dropdown>
    )
  }

  return renderMenu(item)
}

export default NavbarLinksMenu
