import React, { useMemo, useState, useEffect } from 'react'

import {
  UNIT_PX,
  convertUnits,
  nextToggleUnits,
  defaultUnitsForPlatform,
} from 'utils/unitHelpers'
import { clampPositive } from 'utils/math'
import {
  optionObjectsFromCollection,
  groupedOptionsFromCollection,
} from 'utils/formHelpers'

import CardButtonSelect, { CardButtonOption } from 'components/CardButtonSelect'
import { IdType, PageSizeType } from 'EditorApp/types'

export interface CategoryNode {
  readonly id: number
  readonly name: string
  readonly children: Array<CategoryNode>
}

export interface PageSize {
  readonly value: number
  readonly text: string
  readonly width: number
  readonly height: number
  readonly categoryId: number
}

interface Props {
  readonly pageSizes: Array<PageSize>
  readonly categoryTree: Array<CategoryNode>
  readonly platformId: IdType | null
  readonly categoryId: IdType | null
  readonly pageSize: PageSizeType
  readonly width: number
  readonly height: number
  readonly onChangePlatformId: (platformId: IdType) => void
  readonly onChangeCategoryId: (categoryId: IdType | null) => void
  readonly onChangePageSize: (pageSize: PageSizeType) => void
  readonly onChangeWidth: (width: number) => void
  readonly onChangeHeight: (height: number) => void
  readonly onChangeSizeSelected: (selected: boolean) => void
}

interface CustomSizeFieldProps {
  readonly name: string
  readonly size: number
  readonly units: string
  readonly onToggleUnits: () => void
  readonly onChange: (value: number) => void
}

const CustomSizeField = ({
  name,
  size,
  units,
  onToggleUnits,
  onChange,
}: CustomSizeFieldProps) => {
  return (
    <div className="field">
      <div>
        <label htmlFor={`template_page_size_${name}`}>custom {name}</label>{' '}
        <button
          type="button"
          aria-label={`${units} (toggle units between pixels, milimetres, inches)`}
          className="UnitsToggle"
          onClick={onToggleUnits}
        >
          {units}
        </button>
      </div>
      <input
        id={`template_page_size_${name}`}
        value={size > 0 ? convertUnits(size, UNIT_PX, units) : ''}
        type="number"
        required
        min="1"
        onChange={(ev) =>
          onChange(convertUnits(clampPositive(ev.target.value), units, UNIT_PX))
        }
      />
      <input
        type="hidden"
        name={`template[page_size_attributes][${name}]`}
        value={size}
      />
    </div>
  )
}

const TemplatePageSizeSelector = ({
  pageSizes,
  categoryTree,
  platformId,
  categoryId,
  pageSize,
  width,
  height,
  onChangePlatformId,
  onChangeCategoryId,
  onChangePageSize,
  onChangeWidth,
  onChangeHeight,
  onChangeSizeSelected,
}: Props) => {
  const [units, setUnits] = useState<string>(UNIT_PX)
  const customSized = pageSize === 'custom'

  useEffect(() => {
    if (customSized && width > 0 && height > 0) {
      onChangeSizeSelected(true)
    } else if (Number.isInteger(pageSize)) {
      onChangeSizeSelected(true)
    } else {
      onChangeSizeSelected(false)
    }
  }, [onChangeSizeSelected, pageSize, customSized, width, height])

  const updateCustomSize = (width: number, height: number) => {
    onChangeWidth(width)
    onChangeHeight(height)
  }

  const toggleUnits = () => {
    setUnits(nextToggleUnits(units))
  }

  const updatePageSize = (
    pageSize: PageSizeType,
    size?: { width: number; height: number }
  ) => {
    onChangePageSize(pageSize)

    if (size) {
      updateCustomSize(size.width, size.height)
    } else {
      updateCustomSize(0, 0)
    }
  }

  const setSubcategory = (categoryId: number | null) => {
    updatePageSize(null)
    onChangeCategoryId(categoryId)
  }

  const setPlatform = (
    platformId: number,
    { platformName }: { platformName: string }
  ) => {
    setSubcategory(null)
    onChangePlatformId(platformId)
    setUnits(defaultUnitsForPlatform(platformName))
  }

  const CategoryOptions = useMemo(() => {
    if (!platformId) return []

    const platform = categoryTree.find((platform) => platform.id === platformId)
    if (!platform || !platform.children.length) return null

    return groupedOptionsFromCollection(
      platform.children,
      'children',
      'name',
      'id',
      'name'
    )
  }, [platformId, categoryTree])

  const PageSizeOptions = useMemo(() => {
    if (!pageSizes || !categoryId) return null

    const pageSizeOptions = pageSizes.reduce(
      (acc: Array<CardButtonOption>, ps) => {
        if (ps.categoryId !== categoryId) return acc

        const width = convertUnits(ps.width, UNIT_PX, units)
        const height = convertUnits(ps.height, UNIT_PX, units)

        acc.push({
          text: ps.text,
          subText: `${width}${units} x ${height}${units}`,
          value: ps.value,
          size: width > height ? 'wide' : height > width ? 'tall' : 'square',
          callbackData: { width, height },
        })

        return acc
      },
      []
    )

    return [
      ...pageSizeOptions,
      {
        text: 'Custom',
        value: 'custom',
        size: 'square',
      },
    ]
  }, [pageSizes, categoryId, units])

  const PlatformOptions = useMemo(() => {
    return optionObjectsFromCollection(categoryTree, 'id', 'name').map(
      (item) => {
        return { ...item, callbackData: { platformName: item.text } }
      }
    )
  }, [categoryTree])

  return (
    <>
      <div className="field">
        <label htmlFor="platform_name_select">platform</label>
        <CardButtonSelect
          options={PlatformOptions}
          id="platform_name_select"
          inputId="platform_name"
          selectedId={platformId}
          onClick={(value, callbackData) =>
            setPlatform(Number(value), callbackData)
          }
        />
      </div>

      {platformId && (
        <div className="field">
          <label htmlFor="subcategory_name">category / subcategory</label>
          <select
            id="subcategory_name"
            name="template[category_id]"
            className="CreateTemplate__select"
            onChange={(ev) => setSubcategory(clampPositive(ev.target.value))}
            value={categoryId ?? ''}
          >
            <option value="" disabled hidden />
            {CategoryOptions}
          </select>
        </div>
      )}

      {PageSizeOptions && (
        <div className="field">
          <label htmlFor="page_size_select">page size</label>
          <CardButtonSelect
            options={PageSizeOptions}
            id="page_size_select"
            inputId="page_size"
            inputName="template[page_size]"
            onClick={(value, callbackData) =>
              updatePageSize(value as PageSizeType, callbackData)
            }
            selectedId={pageSize}
          />
        </div>
      )}

      {customSized && (
        <>
          <CustomSizeField
            name="width"
            size={width}
            units={units}
            onToggleUnits={toggleUnits}
            onChange={(width) => updateCustomSize(width, height)}
          />
          <CustomSizeField
            name="height"
            size={height}
            units={units}
            onToggleUnits={toggleUnits}
            onChange={(height) => updateCustomSize(width, height)}
          />
        </>
      )}
    </>
  )
}

export default TemplatePageSizeSelector
