import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Form, Select } from 'semantic-ui-react'

class NameableFormSelect extends PureComponent {
  static propTypes = exact({
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
  })

  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue,
    }
  }

  handleChange = (event, { value }) => {
    this.setState({ value })
  }

  render() {
    const { name, options, label, placeholder } = this.props

    const { value } = this.state

    return (
      <Form.Field className="NameableFormSelect">
        <label>{label}</label>
        <Select
          options={options}
          value={value}
          onChange={this.handleChange}
          name={name}
          placeholder={placeholder}
        />
        <input name={name} type="hidden" defaultValue={value} />
      </Form.Field>
    )
  }
}

export default NameableFormSelect
