function maxByHeight(sizes) {
  return sizes.reduce(
    (max, size) => {
      return size.height > max.height ? size : max
    },
    { height: 0 }
  )
}

function maxHeight(sizes) {
  return maxByHeight(sizes).height
}

function scaledToBeSameHeight(sizes) {
  const targetHeight = maxHeight(sizes) + 1

  return sizes.map((size) => {
    const { height, width } = size
    const scalar = targetHeight / height
    return {
      height: targetHeight,
      scaleFactor: scalar,
      width: scalar * width,
    }
  })
}

function scaledToFitWidth(sizes, rowWidth, spacing, numPerRow) {
  const scalar = widthScaleFactor(sizes, rowWidth, spacing, numPerRow)

  return sizes.map((size) => {
    const { height, scaleFactor, width } = size
    return {
      height: height * scalar,
      scaleFactor: (scaleFactor || 1) * scalar,
      width: width * scalar,
    }
  })
}

function sumOfWidths(sizes) {
  return sizes.reduce((sum, { width }) => sum + width, 0)
}

function sumOfSpacing(spacing, numPerRow) {
  return spacing * Math.max(numPerRow - 1, 0)
}

function widthScaleFactor(sizes, rowWidth, spacing, numPerRow) {
  const rowWidthWithoutSpacing = rowWidth - sumOfSpacing(spacing, numPerRow)
  const rowProportion = sizes.length / numPerRow
  const proportionalRowWidth = rowProportion * rowWidthWithoutSpacing
  return proportionalRowWidth / sumOfWidths(sizes)
}

export default function rowFittedSizes(sizes, rowWidth, spacing, numPerRow) {
  return scaledToFitWidth(
    scaledToBeSameHeight(sizes),
    rowWidth,
    spacing,
    numPerRow
  )
}
