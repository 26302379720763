export const UNIT_PX = 'px'
export const UNIT_MM = 'mm'
export const UNIT_IN = 'in'

export const DPI = 72
export const BLEED_MM = 3

export const defaultUnitsForPlatform = (platform) => {
  return platform === 'Print' ? UNIT_MM : UNIT_PX
}

const round = (value, dp = 3) => {
  const rounder = 10 ** dp
  return Math.round(rounder * value) / rounder
}

export const convertPercentToPx = (refWidth, pct, dp) => {
  return round(refWidth * (pct / 100), dp)
}

export const convertPxToPercent = (refWidth, px, dp) => {
  return round((px / refWidth) * 100, dp)
}

export const convertUnits = (value, fromUnit, toUnit = UNIT_PX, dp = 3) => {
  if (typeof value === 'undefined' || value === null) {
    return value
  }

  if (fromUnit === toUnit) {
    return round(value)
  }

  const px =
    fromUnit === UNIT_MM
      ? (value * DPI) / 25.4
      : fromUnit === UNIT_IN
        ? value * DPI
        : value

  return round(
    toUnit === UNIT_MM ? (px * 25.4) / DPI : toUnit === UNIT_IN ? px / DPI : px,
    dp
  )
}

export const nextToggleUnits = (units) => {
  switch (units) {
    case UNIT_PX:
      return UNIT_MM
    case UNIT_MM:
      return UNIT_IN
    default:
      return UNIT_PX
  }
}
