import {
  SET_PLATFORM,
  SET_CATEGORY,
  SET_SUBCATEGORY,
  RESET_FIELDS,
  TOGGLE_UNITS,
  UPDATE_HEIGHT_FIELD,
  UPDATE_WIDTH_FIELD,
} from '../actionTypes/createPageSizes'

import { UNIT_PX, nextToggleUnits } from 'utils/unitHelpers'

export const createPageSizesDefaultState = {
  units: UNIT_PX,
}

export default (state, action) => {
  switch (action.type) {
    case SET_PLATFORM.action:
      return {
        ...state,
        selectedPlatform: action.payload,
      }
    case SET_CATEGORY.action:
      return {
        ...state,
        selectedCategory: action.payload,
      }
    case SET_SUBCATEGORY.action:
      return {
        ...state,
        selectedSubcategory: action.payload,
      }
    case RESET_FIELDS.action:
      return createPageSizesDefaultState
    case TOGGLE_UNITS.action:
      return {
        ...state,
        units: nextToggleUnits(state.units),
      }
    case UPDATE_HEIGHT_FIELD.action:
      return {
        ...state,
        heightFieldValue: action.payload,
      }
    case UPDATE_WIDTH_FIELD.action:
      return {
        ...state,
        widthFieldValue: action.payload,
      }
    default:
      throw new Error(
        `Unsupported action type (page sizes reducer): ${action.type}`
      )
  }
}
