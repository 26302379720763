import React, { useState } from 'react'
import { Form, Dropdown } from 'semantic-ui-react'

interface DivisionData {
  readonly id: number
  readonly name: string
}

interface DivisionSelectorProps {
  inputName: string
  divisions: DivisionData[]
  initialSelectedDivisionIds: number[]
}

const DivisionSelector = ({
  inputName,
  divisions,
  initialSelectedDivisionIds,
}: DivisionSelectorProps) => {
  const [divisionIds, setDivisionIds] = useState(initialSelectedDivisionIds)

  const divisionOptions = divisions.map(({ id, name }) => {
    return { key: id, text: name, value: id }
  })

  const handleChange = (value: number[]) => {
    setDivisionIds(value as number[])
  }

  const hiddenInputs = divisionIds.map((id) => {
    return <input key={id} name={inputName} type="hidden" value={id} />
  })

  return (
    <Form.Field className="DivisionSelector">
      <label>markets</label>
      <Dropdown
        name="divisions"
        fluid
        multiple
        search
        selection
        options={divisionOptions}
        value={divisionIds}
        onChange={(_baseEvent, { value }) => handleChange(value as number[])}
      />

      {hiddenInputs}
    </Form.Field>
  )
}

export default DivisionSelector
