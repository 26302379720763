import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import StyleguideViolationWarning from './StyleguideViolationWarning'

const TooManyLinesWarning = ({ chunks, maxLines }) => {
  let warningMessage = null
  if (chunks.length > maxLines) {
    warningMessage = `maximum of ${maxLines} lines is recommended`
  }

  return <StyleguideViolationWarning message={warningMessage} />
}

TooManyLinesWarning.propTypes = exact({
  maxLines: PropTypes.number.isRequired,
  chunks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ),
})

export default TooManyLinesWarning
