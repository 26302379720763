/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'

export interface SelectOption {
  readonly value: string
  readonly text?: string
  readonly key?: string
  readonly disabled?: boolean
}

export const optionsForSelect = (options: Array<SelectOption>) => {
  return options.map(({ value, text, key, disabled }) => (
    <option key={key || value} value={value} disabled={disabled ?? false}>
      {text || value}
    </option>
  ))
}

export const optionObjectsFromCollection = (
  collection: Array<any>,
  valueProp: string,
  textProp: string,
  keyProp: string | null = null,
  disabledProp: string | null = null
) => {
  const itemKeyProp = keyProp ?? valueProp

  const options: Array<SelectOption> = collection.map((item) => ({
    value: item[valueProp],
    text: item[textProp],
    key: item[itemKeyProp],
    disabled: disabledProp ? item[disabledProp] : false,
  }))

  return options
}

export const optionsFromCollection = (
  collection: Array<any>,
  valueProp: string,
  textProp: string,
  keyProp: string | null = null,
  disabledProp: string | null = null
) => {
  const options = optionObjectsFromCollection(
    collection,
    valueProp,
    textProp,
    keyProp,
    disabledProp
  )

  return optionsForSelect(options)
}

export const groupedOptionsFromCollection = (
  collection: Array<any>,
  groupProp: string,
  groupLabelProp: string,
  valueProp: string,
  textProp: string,
  keyProp: string | null = null,
  disabledProp: string | null = null
) => {
  return collection.map((groupItem) => {
    const items = groupItem[groupProp]

    if (!Array.isArray(items) || !items.length) return null

    const label = groupItem[groupLabelProp]

    return (
      <optgroup key={label} label={label}>
        {optionsFromCollection(
          items,
          valueProp,
          textProp,
          keyProp,
          disabledProp
        )}
      </optgroup>
    )
  })
}

interface OptionsHash {
  [label: string]: any
}

export const groupedOptionsFromHash = (
  groupedHash: OptionsHash,
  valueProp: string,
  textProp: string,
  keyProp: string | null = null,
  disabledProp: string | null = null
) => {
  return Object.entries(groupedHash).map(([label, items]) => {
    return (
      <optgroup key={label} label={label}>
        {optionsFromCollection(
          items,
          valueProp,
          textProp,
          keyProp,
          disabledProp
        )}
      </optgroup>
    )
  })
}
