import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import StyleguideViolationWarning from './StyleguideViolationWarning'

const isOverflowing = (containerRef, allContentRefs) => {
  if (!containerRef.current) return false

  const container = containerRef.current.getBoundingClientRect()

  return allContentRefs.some((contentRef) => {
    if (contentRef.current) {
      const content = contentRef.current.getBoundingClientRect()

      return (
        content.top < container.top ||
        content.left < container.left ||
        content.bottom > container.bottom ||
        content.right > container.right
      )
    }
  })
}

const FixedSizeOverflowWarning = ({
  message,
  containerRef,
  allContentRefs,
}) => {
  const [hasOverflowingContent, setHasOverflowingContent] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const newHasOverflowingContent = isOverflowing(containerRef, allContentRefs)

    if (newHasOverflowingContent !== hasOverflowingContent) {
      setHasOverflowingContent(newHasOverflowingContent)
    }
  })

  const warningMessage = hasOverflowingContent ? message : null

  return <StyleguideViolationWarning message={warningMessage} />
}

FixedSizeOverflowWarning.propTypes = exact({
  message: PropTypes.string.isRequired,
  containerRef: PropTypes.object.isRequired,
  allContentRefs: PropTypes.arrayOf(PropTypes.object.isRequired),
})

export default FixedSizeOverflowWarning
