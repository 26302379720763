import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Icon } from 'semantic-ui-react'
import classnames from 'classnames'

const PAGE_COUNT_SUFFIX = 'p'

class CardInfo extends PureComponent {
  static propTypes = exact({
    category: PropTypes.string.isRequired,
    createdAt: PropTypes.instanceOf(Date),
    updatedAt: PropTypes.instanceOf(Date),
    ownerName: PropTypes.string,
    pageCount: PropTypes.number.isRequired,
    pageDimensions: PropTypes.string.isRequired,
    section: PropTypes.string,
    status: PropTypes.oneOf(['personal', 'published']).isRequired,
  })

  dateRow(label, date) {
    if (!date) return null

    return (
      <div className="CardInfo__row">
        <time dateTime={date.toISOString()}>
          <span>
            {label}: {date.toLocaleDateString()}
          </span>
        </time>
      </div>
    )
  }

  ownerName() {
    const { ownerName } = this.props

    if (!ownerName) return null

    return (
      <div className="CardInfo__row">
        <span>{ownerName}</span>
      </div>
    )
  }

  section() {
    const { section } = this.props

    if (!section) return null

    return (
      <div className="CardInfo__row">
        <Icon name="tag" size="small" />
        <span>{section}</span>
      </div>
    )
  }

  personal(isPersonal) {
    if (!isPersonal) return null

    return (
      <div className="CardInfo__row CardInfo__row--personal">
        <span>PRIVATE</span>
      </div>
    )
  }

  render() {
    const {
      category,
      pageCount,
      pageDimensions,
      status,
      createdAt,
      updatedAt,
    } = this.props

    const isPersonal = status == 'personal'

    return (
      <div
        className={classnames({
          CardInfo: true,
          'CardInfo--personal': isPersonal,
        })}
      >
        <div className="CardInfo__row">
          <span>{category}</span>
        </div>

        <div className="CardInfo__row">
          <span>
            {pageCount}
            {PAGE_COUNT_SUFFIX}
          </span>
          ,&nbsp;
          <span>{pageDimensions}</span>
        </div>

        {this.ownerName()}
        {this.dateRow('created', createdAt)}
        {this.dateRow('updated', updatedAt)}
        {this.section()}
        {this.personal(isPersonal)}
      </div>
    )
  }
}

export default CardInfo
