import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ElementTooSmallWarning from 'components/ElementTooSmallWarning'

const QrCodeElement = ({ isActive, element }) => {
  const svgSetter = { __html: element.renderedSvg }

  return (
    <div className="QrCodeElement" data-element-id={element.id}>
      {isActive && (
        <ElementTooSmallWarning
          minimumSize={{ width: 72, height: 72 }}
          actualSize={{ width: element.width, height: element.height }}
        />
      )}
      <div
        className="QrCodeElement__container"
        dangerouslySetInnerHTML={svgSetter}
      />
    </div>
  )
}

QrCodeElement.propTypes = exact({
  isActive: PropTypes.bool,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    renderedSvg: PropTypes.string.isRequired,
  }),
})

export default QrCodeElement
