import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Form, Select } from 'semantic-ui-react'

class PrintOptionAndQuantitySelect extends PureComponent {
  static propTypes = exact({
    print_options: PropTypes.arrayOf(PropTypes.object).isRequired,
    print_option_id: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number,
  })

  constructor(props) {
    super(props)
    var print_option = this.findPrintOption(this.props.print_option_id)
    this.state = {
      print_option: print_option,
      quantity: this.props.quantity,
      price: this.props.price,
    }
  }

  findPrintOption = (value) => {
    return this.props.print_options[
      this.props.print_options
        .map((e) => {
          return e.value
        })
        .indexOf(value)
    ]
  }

  handlePrintOptionChange = (event, { value }) => {
    var print_option = this.findPrintOption(value)
    this.setState({
      print_option: print_option,
      quantity: Object.keys(print_option.quantities)[0],
      price: print_option.quantities[Object.keys(print_option.quantities)[0]],
    })
  }

  handleQuantityChange = (event, { value }) => {
    const { print_option } = this.state
    this.setState({
      quantity: value,
      price: print_option.quantities[value],
    })
  }

  render() {
    const { print_options } = this.props
    const { print_option, quantity } = this.state

    return (
      <div style={{ marginBottom: '14px' }}>
        <div className="field">
          <Form.Field>
            <label>{'Print option'}</label>
            <Select
              options={print_options}
              value={print_option.value}
              onChange={this.handlePrintOptionChange}
              name={'print_job[print_option_id]'}
            />
            <input
              name={'print_job[print_option_id]'}
              type="hidden"
              defaultValue={print_option.value}
            />
          </Form.Field>
        </div>
        <div className="field">
          <Form.Field>
            <label>{'Quantity'}</label>
            <Select
              options={Object.keys(print_option.quantities).map((e) => {
                return {
                  text: print_option.quantities[e]
                    ? e + ' (' + print_option.quantities[e] + ')'
                    : e,
                  value: parseInt(e),
                }
              })}
              value={quantity}
              onChange={this.handleQuantityChange}
              name={'print_job[quantity]'}
            />
            <input
              name={'print_job[quantity]'}
              type="hidden"
              defaultValue={quantity}
            />
          </Form.Field>
        </div>
      </div>
    )
  }
}

export default PrintOptionAndQuantitySelect
