import {
  SET_PLATFORM,
  SET_CATEGORY,
  SET_SUBCATEGORY,
  RESET_FIELDS,
  TOGGLE_UNITS,
  UPDATE_HEIGHT_FIELD,
  UPDATE_WIDTH_FIELD,
} from '../actionTypes/createPageSizes'

export function toggleUnits() {
  return { type: TOGGLE_UNITS.action }
}

export function setPlatform(platform) {
  return { type: SET_PLATFORM.action, payload: platform }
}

export function setCategory(category) {
  return { type: SET_CATEGORY.action, payload: category }
}

export function setSubcategory(subcategory) {
  return { type: SET_SUBCATEGORY.action, payload: subcategory }
}

export function resetFields() {
  return { type: RESET_FIELDS.action }
}

export function updateWidthField(width) {
  return { type: UPDATE_WIDTH_FIELD.action, payload: width }
}

export function updateHeightField(height) {
  return { type: UPDATE_HEIGHT_FIELD.action, payload: height }
}
