import orderByZ from 'utils/orderByZ'

export function getSelectedPage(state) {
  const pageId = state.editor.selected.pageId
  const markup = state.markup.present
  const page = markup.pages[pageId]
  return page
}

export function getSelectedElement(state) {
  const page = getSelectedPage(state)
  const elementId = state.editor.selected.elementId
  const element = page.elements[elementId]
  return element
}

export function getTheme(state, newTheme = null) {
  const houseStyle = state.config.houseStyle
  const themes = houseStyle.themes

  const selectedTheme = state.markup.present.theme

  return themes[newTheme || selectedTheme || houseStyle.defaultTheme]
}

export function getThemesForElementType(state, type) {
  const houseStyle = state.config.houseStyle
  const themes = houseStyle.themes

  const typeThemes = {}

  for (const [name, theme] of Object.entries(themes)) {
    const elementTheme = theme.elements[type]

    if (elementTheme) {
      typeThemes[name] = elementTheme
    }
  }

  return typeThemes
}

// text chunk helpers
export const stateToEditorFormat = (chunks) => {
  return chunks.map(({ text }) => {
    const content = text || '(missing)'

    return {
      type: 'paragraph',
      children: [{ text: content }],
    }
  })
}

export const editorFormatToState = (editorValue) => {
  return editorValue.map(({ children: [chunk] }) => chunk)
}

export const pageElements = (page) => {
  return orderByZ(page.elements).filter((e) => !e.disabled)
}
