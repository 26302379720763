import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const TableDropdown = ({
  placeholder,
  options,
  onChange = () => {},
  selectedValue,
  ...rest
}) => {
  return (
    <select
      className="TableDropdown__select"
      onChange={onChange}
      defaultValue={selectedValue ?? ''}
      {...rest}
    >
      {placeholder && (
        <option value="" disabled hidden>
          {placeholder}
        </option>
      )}
      {options?.map(({ value, text }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </select>
  )
}

TableDropdown.propTypes = exact({
  'aria-label': PropTypes.string,
  form: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rest: PropTypes.object,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export default TableDropdown
