import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classnames from 'classnames'

const Page = ({
  children,
  width,
  height,
  page,
  isSelected,
  isSelectable = true,
  showBleed,
  zoom,
  ...rest
}) => {
  const hasElements = Object.keys(page.elements).length
  const outlineWidth = zoom ? 2 / zoom : 1

  return (
    <div
      className={classnames({
        Page: true,
        'Page--is-selectable': isSelectable,
        'Page--empty': !hasElements,
        'Page--active': isSelected,
        'Page--show-bleed': showBleed,
      })}
      style={{
        width,
        height,
        // hide CSS outline when showing bleed and use bleed-outline element instead
        ...(showBleed ? { outlineWidth: 0 } : { outlineWidth }),
      }}
      data-page-id={page.id}
      {...rest}
    >
      {children}
      {showBleed && (
        <div
          className="Page__bleed-outline"
          style={{
            outlineWidth,
          }}
        />
      )}
    </div>
  )
}

Page.propTypes = exact({
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  page: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  showBleed: PropTypes.bool,
  zoom: PropTypes.number,
  isSelectable: PropTypes.bool,
  onClick: PropTypes.func,
  onScroll: PropTypes.func,
})

export default Page
