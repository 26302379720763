import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { verticalAlignmentToCss } from 'utils/richTextHelpers'

class RichText extends PureComponent {
  static propTypes = exact({
    element: PropTypes.object.isRequired,
  })

  style() {
    const { element } = this.props
    const { height, textRotate, width } = element

    const alignContent = verticalAlignmentToCss(element.textVerticalAlign)

    switch (textRotate) {
      case 90:
      case 270:
        return {
          alignContent,
          height: width,
          transform: [
            `translate(${(width - height) / 2}px, ${(height - width) / 2}px)`,
            `rotate(${textRotate}deg)`,
          ].join(' '),
          width: height,
        }
      default:
        return {
          alignContent,
          height,
          transform: [`translate(0px, 0px)`, `rotate(${textRotate}deg)`].join(
            ' '
          ),
          width,
        }
    }
  }

  render() {
    const { element } = this.props
    const { body } = element

    return (
      <div
        className="RichText"
        dangerouslySetInnerHTML={{ __html: body }}
        style={this.style()}
      />
    )
  }
}

export default RichText
