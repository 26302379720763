import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const StyleguideViolationWarning = ({ message }) => {
  if (!message) {
    return null
  }

  return (
    <div className="StyleguideViolationWarning">
      <div className="StyleguideViolationWarning__warning-border" />
      <div className="StyleguideViolationWarning__warning-message">
        {message}
      </div>
    </div>
  )
}

StyleguideViolationWarning.propTypes = exact({
  message: PropTypes.string,
})

export default StyleguideViolationWarning
