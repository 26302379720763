import React, { useState } from 'react'

import apiRequest from 'utils/apiRequest'

type IdType = number | null

export interface SectionNode {
  readonly id: number
  name: string
  children: SectionNode[]
}

enum EditType {
  None,
  Edit,
  Add,
}

interface EditState {
  type: EditType
  id: IdType
}

interface Props {
  initialSections: SectionNode[]
  addPath: string
  updatePath: string
}

const EditSections = ({ initialSections, addPath, updatePath }: Props) => {
  const [sections, setSections] = useState(initialSections)
  const [edit, setEdit] = useState<EditState>({
    type: EditType.None,
    id: null,
  })

  const handleAddNewSection = (parentId: IdType) => {
    setEdit({ ...edit, type: EditType.Add, id: parentId })
  }

  const handleEditSection = (id: number) => {
    setEdit({ ...edit, type: EditType.Edit, id: id })
  }

  const handleSave = (name: string) => {
    const parentId = edit.type === EditType.Add ? edit.id : null

    apiRequest({
      method: edit.type === EditType.Add ? 'POST' : 'PATCH',
      url:
        edit.type === EditType.Add
          ? addPath
          : updatePath.replace(':id', `${edit.id}`),
      payload: { section: { name: name }, parent_id: parentId },
      reloadOnAuthError: true,
      requestDescription: 'saving a section',
    }).then((response) => {
      if (response && response.ok) {
        setSections(response.body as SectionNode[])
      }

      handleCancel()
    })
  }

  const handleDeleteSection = (id: number) => {
    if (!window.confirm('Are you sure?')) return

    apiRequest({
      method: 'DELETE',
      url: updatePath.replace(':id', `${id}`),
      payload: {},
      reloadOnAuthError: true,
      requestDescription: 'deleting a section',
    }).then((response) => {
      if (response && response.ok) {
        setSections(response.body as SectionNode[])
      }

      handleCancel()
    })
  }

  const handleCancel = () => {
    setEdit({ ...edit, type: EditType.None, id: null })
  }

  const editingInput = (defaultValue: string) => {
    return (
      <div className="EditSections__row__editing">
        <div className="EditSections__row__name">
          <input id="section_name" defaultValue={defaultValue} />
        </div>

        <div className="EditSections__row__buttons">
          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault()
              const target = e.target as HTMLInputElement
              handleSave(target.form!.section_name.value)
            }}
            className="ui button compact primary"
          >
            save
          </button>
          <button
            type="button"
            onClick={() => handleCancel()}
            className="ui button compact negative"
          >
            cancel
          </button>
        </div>
      </div>
    )
  }

  const addSectionButton = (parentId: IdType, text: string) => {
    if (edit.type === EditType.Add && edit.id === parentId) {
      return editingInput('')
    }

    return (
      <button
        type="button"
        onClick={() => handleAddNewSection(parentId)}
        className="ui button basic"
      >
        {text}
      </button>
    )
  }

  const editSectionButton = (tagType: string, id: number, name: string) => {
    if (edit.type === EditType.Edit && edit.id === id) {
      return editingInput(name)
    }

    const Tag = `${tagType}` as keyof JSX.IntrinsicElements

    return (
      <>
        <div className="EditSections__row__name">
          <Tag>{name}</Tag>
        </div>

        <div className="EditSections__row__buttons">
          <i
            className="icon Icon Icon--edit"
            onClick={() => handleEditSection(id)}
          >
            <svg>
              <use xlinkHref="/error/icons.svg#edit"></use>
            </svg>
          </i>
          <i
            className="icon Icon Icon--trash"
            onClick={() => handleDeleteSection(id)}
          >
            <svg>
              <use xlinkHref="/error/icons.svg#trash"></use>
            </svg>
          </i>
        </div>
      </>
    )
  }

  const SectionOptions = () => {
    if (!sections) return null

    return sections.map((section) => {
      return (
        <div key={section.id} className="EditSections__section">
          <div className="EditSections__row EditSections__row--title">
            {editSectionButton('h2', section.id, section.name)}
          </div>

          {section.children.map((child) => (
            <div key={child.id} className="EditSections__row">
              {editSectionButton('span', child.id, child.name)}
            </div>
          ))}

          {!section.children.length && (
            <div className="EditSections__notice">
              Please <strong>add sections</strong> to this group, it will not be
              visible until sections are added.
            </div>
          )}

          <div className="EditSections__row--add">
            {addSectionButton(section.id, 'add section')}
          </div>
        </div>
      )
    })
  }

  return (
    <div className="EditSections">
      <form className="ui form">
        {SectionOptions()}

        {addSectionButton(null, 'add new group')}
      </form>
    </div>
  )
}

export default EditSections
