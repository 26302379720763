import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import CardControls from 'components/CardControls'
import CardInfo from 'components/CardInfo'
import CardTitle from 'components/CardTitle'
import DocPreview from 'components/DocPreview'
import LinkWithOverlay from 'components/LinkWithOverlay'
import Scaled from 'components/Scaled'

const DocCard = ({
  category,
  createdAt,
  updatedAt,
  docName,
  fontFamily,
  height,
  markup,
  menuLinks,
  overlayLink,
  ownerName,
  pageDimensions,
  scale,
  width,
  section,
  status,
  unviewed,
}) => {
  const createdAtDate = createdAt ? new Date(createdAt) : null
  const updatedAtDate = updatedAt ? new Date(updatedAt) : null
  const pageCount = markup['pageOrder'].length

  return (
    <div
      className="DocCard"
      style={{
        width: width,
      }}
    >
      <div className="DocCard__header">
        <LinkWithOverlay
          ariaLabel={docName}
          href={overlayLink.href}
          overlayText={overlayLink.text}
        >
          <Scaled height={height} scale={scale} width={width}>
            <DocPreview fontFamily={fontFamily} markup={markup} zoom={scale} />
          </Scaled>
        </LinkWithOverlay>
      </div>

      <section className="DocCard__footer">
        <div className="DocCard__title">
          <CardTitle docName={docName} />
          <CardControls
            direction="left"
            menuIsUpward={false}
            menuLinks={menuLinks}
          />
        </div>

        <CardInfo
          category={category}
          createdAt={createdAtDate}
          updatedAt={updatedAtDate}
          ownerName={ownerName}
          pageCount={pageCount}
          pageDimensions={pageDimensions}
          section={section}
          status={status}
        />
      </section>

      {unviewed && <div className="DocCard__unviewed-badge" />}
    </div>
  )
}

DocCard.propTypes = exact({
  category: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  docName: PropTypes.string.isRequired,
  fontFamily: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  markup: PropTypes.object.isRequired,
  menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  overlayLink: PropTypes.object.isRequired,
  ownerName: PropTypes.string.isRequired,
  pageDimensions: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  section: PropTypes.string,
  status: PropTypes.oneOf(['personal', 'published']).isRequired,
  unviewed: PropTypes.bool,
})

export default DocCard
