import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { styleForColorOrGradient } from 'utils/colorHelpers'

export default class RegionElement extends PureComponent {
  static propTypes = exact({
    element: PropTypes.object.isRequired,
  })

  render() {
    const { element } = this.props

    const { width, height, backgroundColor, borderRadius } = element

    return (
      <div
        className="RegionElement"
        style={{
          width,
          height,
          borderRadius,
          ...styleForColorOrGradient(backgroundColor),
        }}
        data-element-id={element.id}
      />
    )
  }
}
