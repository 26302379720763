export const ADD_ELEMENT = {
  action: 'ADD_ELEMENT',
  isUndoable: true,
}

export const ADD_PAGE_AFTER_ID = {
  action: 'ADD_PAGE_AFTER_ID',
  isUndoable: false,
}

export const CLOSE_DOCUMENT_RESIZE_MODAL = {
  action: 'CLOSE_DOCUMENT_RESIZE_MODAL',
  isUndoable: false,
}

export const CLOSE_IMAGE_PICKER = {
  action: 'CLOSE_IMAGE_PICKER',
  isUndoable: false,
}

export const CROP_IMAGE = {
  action: 'CROP_IMAGE',
  isUndoable: true,
}

export const DELETE_PAGE_BY_ID = {
  action: 'DELETE_PAGE_BY_ID',
  isUndoable: false,
}

export const DESELECT_A_MODE = {
  action: 'DESELECT_A_MODE',
  isUndoable: false,
}

export const DESELECT_ELEMENT = {
  action: 'DESELECT_ELEMENT',
  isUndoable: false,
}

export const DESELECT_UL_MODE = {
  action: 'DESELECT_UL_MODE',
  isUndoable: false,
}

export const DISABLE_DRAGGING_MODE = {
  action: 'DISABLE_DRAGGING_MODE',
  isUndoable: false,
}

export const DISABLE_ELEMENT_PERMISSION = {
  action: 'DISABLE_ELEMENT_PERMISSION',
  isUndoable: false,
}

export const DUPLICATE_ELEMENT = {
  action: 'DUPLICATE_ELEMENT',
  isUndoable: true,
}

export const DUPLICATE_PAGE_BY_ID = {
  action: 'DUPLICATE_PAGE_BY_ID',
  isUndoable: false,
}

export const ENABLE_ELEMENT_PERMISSION = {
  action: 'ENABLE_ELEMENT_PERMISSION',
  isUndoable: false,
}

export const FETCH_FILTERS_FAILURE = {
  action: 'FETCH_FILTERS_FAILURE',
  isUndoable: false,
}

export const FETCH_FILTERS_PENDING = {
  action: 'FETCH_FILTERS_PENDING',
  isUndoable: false,
}

export const FETCH_FILTERS_SUCCESS = {
  action: 'FETCH_FILTERS_SUCCESS',
  isUndoable: false,
}

export const FETCH_PREVIEWS_FAILURE = {
  action: 'FETCH_PREVIEWS_FAILURE',
  isUndoable: false,
}

export const FETCH_PREVIEWS_PENDING = {
  action: 'FETCH_PREVIEWS_PENDING',
  isUndoable: false,
}

export const FETCH_PREVIEWS_SUCCESS = {
  action: 'FETCH_PREVIEWS_SUCCESS',
  isUndoable: false,
}

export const FETCH_SPECIMENS_FAILURE = {
  action: 'FETCH_SPECIMENS_FAILURE',
  isUndoable: false,
}

export const FETCH_SPECIMENS_SUCCESS = {
  action: 'FETCH_SPECIMENS_SUCCESS',
  isUndoable: false,
}

export const FLIP_IMAGE = {
  action: 'FLIP_IMAGE',
  isUndoable: true,
}

export const IMAGE_FETCH_PENDING = {
  action: 'IMAGE_FETCH_PENDING',
  isUndoable: false,
}

export const IMAGE_FETCH_DONE = {
  action: 'IMAGE_FETCH_DONE',
  isUndoable: false,
}

export const OPEN_DOCUMENT_RESIZE_MODAL = {
  action: 'OPEN_DOCUMENT_RESIZE_MODAL',
  isUndoable: false,
}

export const OPEN_IMAGE_PICKER = {
  action: 'OPEN_IMAGE_PICKER',
  isUndoable: false,
}

export const OPEN_SPECIMEN_PICKER = {
  action: 'OPEN_SPECIMEN_PICKER',
  isUndoable: false,
}

export const BACK_FROM_SPECIMEN_PICKER = {
  action: 'BACK_FROM_SPECIMEN_PICKER',
  isUndoable: false,
}

export const POPULATE_SPECIMENS_LIST = {
  action: 'POPULATE_SPECIMENS_LIST',
  isUndoable: false,
}

export const REMOVE_ELEMENT = {
  action: 'REMOVE_ELEMENT',
  isUndoable: true,
}

export const REPOSITION_RESIZE_ELEMENT = {
  action: 'REPOSITION_RESIZE_ELEMENT',
  isUndoable: true,
}

export const RESIZE_DOCUMENT = {
  action: 'RESIZE_DOCUMENT',
  isUndoable: true,
}

export const RESIZING_STARTED = {
  action: 'RESIZING_STARTED',
  isUndoable: false,
}

export const RESIZING_STOPPED = {
  action: 'RESIZING_STOPPED',
  isUndoable: false,
}

export const SAVE_FAILURE = {
  action: 'SAVE_FAILURE',
  isUndoable: false,
}

export const SAVE_PENDING = {
  action: 'SAVE_PENDING',
  isUndoable: false,
}

export const SAVE_SUCCESS = {
  action: 'SAVE_SUCCESS',
  isUndoable: false,
}

export const SELECT_A_MODE = {
  action: 'SELECT_A_MODE',
  isUndoable: false,
}

export const SELECT_ELEMENT = {
  action: 'SELECT_ELEMENT',
  isUndoable: false,
}

export const SELECT_PAGE = {
  action: 'SELECT_PAGE',
  isUndoable: false,
}

export const SELECT_UL_MODE = {
  action: 'SELECT_UL_MODE',
  isUndoable: false,
}

export const SET_DOCUMENT_PERMISSION = {
  action: 'SET_DOCUMENT_PERMISSION',
  isUndoable: false,
}

export const SET_PAGE_ORDER = {
  action: 'SET_PAGE_ORDER',
  isUndoable: false,
}

export const SET_TEXT_SELECTION = {
  action: 'SET_TEXT_SELECTION',
  isUndoable: false,
}

export const SET_ZOOM = {
  action: 'SET_ZOOM',
  isUndoable: false,
}

export const SWITCH_THEME = {
  action: 'SWITCH_THEME',
  isUndoable: true,
}

export const TERMINATE_UPLOAD_PROGRESS = {
  action: 'TERMINATE_UPLOAD_PROGRESS',
  isUndoable: false,
}

export const TOGGLE_GRID = {
  action: 'TOGGLE_GRID',
  isUndoable: false,
}

export const TOGGLE_POSITION_UNITS = {
  action: 'TOGGLE_POSITION_UNITS',
  isUndoable: false,
}

export const SHOW_BLEED = {
  action: 'SHOW_BLEED',
  isUndoable: false,
}

export const UPDATE_DOC = {
  action: 'UPDATE_DOC',
  isUndoable: true,
}

export const UPDATE_ELEMENT = {
  action: 'UPDATE_ELEMENT',
  isUndoable: true,
}

export const UPDATE_UPLOAD_PROGRESS = {
  action: 'UPDATE_UPLOAD_PROGRESS',
  isUndoable: false,
}

export const SET_NOTIFICATION = {
  action: 'SET_NOTIFICATION',
  isUndoable: false,
}
