import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import ElementContainer from 'components/ElementContainer'
import Page from 'components/Page'
import ButtonElement from 'components/ButtonElement'
import ImageElement from 'components/ImageElement'
import QrCodeElement from 'components/QrCodeElement'
import RegionElement from 'components/RegionElement'
import RichTextElement from 'components/RichTextElement'
import SpeechBubblesElement from 'components/SpeechBubblesElement'
import TempoTeamHeadingElement from 'components/TempoTeamHeadingElement'
import TitleTextElement from 'components/TitleTextElement'
import GradientTextElement from 'components/GradientTextElement'

import { pageElements } from 'utils/stateHelpers'

const componentFor = ({ element, fontFamily, zoom, previewImage }) => {
  switch (element.type) {
    case 'button':
      return <ButtonElement element={element} />
    case 'image':
      return <ImageElement element={element} preview={previewImage} />
    case 'qrcode':
      return <QrCodeElement element={element} />
    case 'region':
      return <RegionElement element={element} />
    case 'richtext':
      return <RichTextElement element={element} fontFamily={fontFamily} />
    case 'speechbubbles':
      return (
        <SpeechBubblesElement
          element={element}
          fontFamily={fontFamily}
          zoom={zoom}
        />
      )
    case 'tempoteamheading':
      return <TempoTeamHeadingElement element={element} />
    case 'titletext':
      return (
        <TitleTextElement
          element={element}
          fontFamily={fontFamily}
          zoom={zoom}
        />
      )
    case 'gradienttext':
      return <GradientTextElement element={element} />
    default:
      return null
  }
}

const DocPreview = ({ fontFamily, markup, zoom, previewImage = true }) => {
  const [firstPageId] = markup.pageOrder
  const firstPage = markup.pages[firstPageId]
  const elements = pageElements(firstPage)

  return (
    <div className="DocPreview" style={{ fontFamily }}>
      <Page
        width={markup.pageWidth}
        height={markup.pageHeight}
        page={firstPage}
        isSelected={false}
        isSelectable={false}
      >
        {elements.map((element) => {
          return (
            <ElementContainer element={element} key={element.id}>
              {componentFor({ element, fontFamily, zoom, previewImage })}
            </ElementContainer>
          )
        })}
      </Page>
    </div>
  )
}

DocPreview.propTypes = exact({
  fontFamily: PropTypes.string.isRequired,
  markup: PropTypes.object.isRequired,
  zoom: PropTypes.number,
  previewImage: PropTypes.bool,
})

export default DocPreview
