import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Dropdown } from 'semantic-ui-react'

import SearchIcon from '../images/search.svg'

class SearchForm extends PureComponent {
  static UTF8_ENFORCER_ELEMENT = (
    <input type="hidden" name="utf8" defaultValue={'\u2713'} />
  )

  static propTypes = exact({
    url: PropTypes.string.isRequired,
    retainedParams: PropTypes.object.isRequired,
    sortChoices: PropTypes.object.isRequired,
    sort: PropTypes.string,
    query: PropTypes.string,
  })

  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      isChoosingSort: false,
    }

    this.formRef = React.createRef()
    this.onSubmit = this.onSubmit.bind(this)
    this.onSortChange = this.onSortChange.bind(this)
    this.onQueryIconClick = this.onQueryIconClick.bind(this)
  }

  sortOptions() {
    const { sortChoices } = this.props

    return Object.keys(sortChoices).map((key) => ({
      text: key,
      value: sortChoices[key],
    }))
  }

  sortText() {
    const { sortChoices, sort } = this.props

    return Object.keys(sortChoices).find((key) => sortChoices[key] == sort)
  }

  onSubmit() {
    this.setState({ ...this.state, isSubmitting: true })
  }

  onSortChange(ev, { value: newSort }) {
    const form = this.formRef.current
    if (form.sort.value == newSort) return

    form.sort.value = newSort
    form.submit()
  }

  onQueryIconClick() {
    this.formRef.current.submit()
  }

  renderRetainedParameters() {
    const { retainedParams } = this.props

    return Object.entries(retainedParams).map(([name, value]) => {
      return <input type="hidden" key={name} name={name} defaultValue={value} />
    })
  }

  renderSort() {
    const { sort } = this.props
    const { isSubmitting } = this.state

    return (
      <div className="SearchForm__sort">
        <input type="hidden" name="sort" defaultValue={sort} />
        <Dropdown
          options={this.sortOptions()}
          value={sort}
          text={this.sortText()}
          onOpen={this.onSortOpen}
          onClose={this.onSortClose}
          onChange={this.onSortChange}
          disabled={isSubmitting}
          selection
          fluid
        />
      </div>
    )
  }

  renderQuery() {
    const { query } = this.props
    const { isSubmitting } = this.state

    return (
      <div className="ui icon input SearchForm__query">
        <input
          type="search"
          name="query"
          defaultValue={query}
          readOnly={isSubmitting}
        />
        <i className="icon Icon Icon--search" onClick={this.onQueryIconClick}>
          <SearchIcon />
        </i>
      </div>
    )
  }

  render() {
    const { url } = this.props

    return (
      <form
        method="get"
        action={url}
        acceptCharset="UTF-8"
        className="SearchForm ui form"
        ref={this.formRef}
        onSubmit={this.onSubmit}
      >
        {this.constructor.UTF8_ENFORCER_ELEMENT}
        {this.renderRetainedParameters()}
        {this.renderSort()}
        {this.renderQuery()}
      </form>
    )
  }
}

export default SearchForm
