import React, { PureComponent } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Menu } from 'semantic-ui-react'

class Navbar extends PureComponent {
  static defaultProps = {
    isFullWidth: true,
  }

  static propTypes = exact({
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    rawHtmlContent: PropTypes.string,
    links: PropTypes.object.isRequired,
    homeIconSrc: PropTypes.string.isRequired,
    isFullWidth: PropTypes.bool,
  })

  render() {
    const { children, rawHtmlContent, homeIconSrc, isFullWidth, links } =
      this.props

    // For nav content passed in via Rails helper
    const rawHtmlMenu = rawHtmlContent && (
      <Menu.Menu
        position="right"
        dangerouslySetInnerHTML={{ __html: rawHtmlContent }}
      ></Menu.Menu>
    )

    return (
      <Menu
        className={classnames({
          Navbar: true,
          'Navbar--full-width': isFullWidth,
        })}
        fixed="top"
        size="large"
        pointing={true}
        secondary={true}
      >
        <Menu.Item
          aria-label="home"
          header
          link={true}
          href={links.home.path}
          name="home"
        >
          <img src={homeIconSrc} className="ui image Navbar__logo" />
        </Menu.Item>

        {rawHtmlMenu}
        {children}
      </Menu>
    )
  }
}

export default Navbar
