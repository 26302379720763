import { useState, useEffect } from 'react'

const useFontsLoaded = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false)

  useEffect(() => {
    document.fonts.ready.then(function () {
      setFontsLoaded(true)
    })
  }, [])

  return fontsLoaded
}

export default useFontsLoaded
