import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Dimmer, Loader, Dropdown } from 'semantic-ui-react'

import { creationPrintJobUrl } from 'utils/creationPrintJobPaths'

import { attemptCreationExport } from 'EditorApp/actions/doc'

import LinkSharer from 'components/LinkSharer'

const ExportMenu = ({
  creation,
  isItem,
  printable,
  shareable,
  exportLinks,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const startExport = (event, propsData) => {
    event.preventDefault()
    setIsExporting(true)
    attemptCreationExport(propsData.href, endExport)
  }

  const endExport = () => {
    setIsExporting(false)
  }

  const dropdownLinks = exportLinks.map((linksHash) => (
    <Dropdown.Item
      key={linksHash.text}
      icon={linksHash.icon}
      text={linksHash.text}
      as="a"
      href={linksHash.href}
      onClick={(event, propsData) => startExport(event, propsData)}
    />
  ))

  const printerLink = printable && (
    <Dropdown.Item
      key="printer"
      icon="print"
      text="commercial printer"
      as="a"
      href={creationPrintJobUrl(creation.id)}
    />
  )

  const shareLink = shareable && (
    <a
      onClick={() => setModalOpen(true)}
      role="option"
      className="item ExportMenu__share-link"
    >
      <i aria-hidden="true" className="linkify icon"></i>
      <span className="text">share link</span>
    </a>
  )

  return (
    <>
      <Dimmer active={isExporting} page>
        <Loader indeterminate>Preparing File</Loader>
      </Dimmer>

      <Dropdown item={isItem} text="export">
        <Dropdown.Menu className={isItem ? '' : 'ExportMenu__dropdown'}>
          {dropdownLinks}
          {shareLink}
          {printerLink}
        </Dropdown.Menu>
      </Dropdown>
      {shareable && (
        <LinkSharer
          isOpen={modalOpen}
          closeLinkSharer={() => setModalOpen(false)}
          exportLinks={exportLinks}
        />
      )}
    </>
  )
}

ExportMenu.propTypes = exact({
  creation: PropTypes.object.isRequired,
  isItem: PropTypes.bool,
  printable: PropTypes.bool,
  shareable: PropTypes.bool,
  exportLinks: PropTypes.array.isRequired,
})

export default ExportMenu
