import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Dropdown } from 'semantic-ui-react'
import { sendToDataLayer, dropdownFilterEvent } from '../utils/dataLayer'

const IndexFilterer = ({ value, name, options, blankValue }) => {
  const handleChange = (_, { value: inputValue }) => {
    if (value !== inputValue) {
      const searchParams = new URLSearchParams(window.location.search)

      if (inputValue === blankValue) {
        searchParams.delete(name)
      } else {
        searchParams.delete('page')
        searchParams.set(name, inputValue)
      }

      sendToDataLayer(dropdownFilterEvent(name, value))

      window.location.search = searchParams.toString()
    }
  }

  return (
    <>
      <Dropdown
        name={name}
        placeholder={name}
        options={options}
        selection
        value={value}
        onChange={handleChange}
      />
      <input name={name} type="hidden" defaultValue={value} />
    </>
  )
}

IndexFilterer.propTypes = exact({
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blankValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

export default IndexFilterer
