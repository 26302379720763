const letterSpacingFor = (fontSize) => {
  switch (true) {
    case fontSize < 18:
      return -0.01 // -10 tracking
    case fontSize < 30:
      return -0.02 // -20 tracking
    case fontSize < 40:
      return -0.04 // -40 tracking
    case fontSize < 50:
      return -0.05 // -50 tracking
    default:
      return -0.06 // -60 tracking
  }
}

export default letterSpacingFor
