export const SET_PLATFORM = {
  action: 'setPlatform',
}

export const SET_CATEGORY = {
  action: 'setCategory',
}

export const SET_SUBCATEGORY = {
  action: 'setSubcategory',
}

export const RESET_FIELDS = {
  action: 'resetFields',
}

export const TOGGLE_UNITS = {
  action: 'toggleUnits',
}

export const UPDATE_HEIGHT_FIELD = {
  action: 'updateHeightField',
}

export const UPDATE_WIDTH_FIELD = {
  action: 'updateWidthField',
}
