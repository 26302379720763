import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import ChromeZoomFix from 'components/ChromeZoomFix'

const Scaled = ({ children, height, scale, width }) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        height: height,
        overflow: 'hidden',
        width: width,
      }}
    >
      <div
        style={{
          display: 'inline-flex',
          pointerEvents: 'none',
          transformOrigin: 'top left',
          transform: `scale(${scale})`,
        }}
      >
        <ChromeZoomFix />

        {children}
      </div>
    </div>
  )
}

Scaled.propTypes = exact({
  children: PropTypes.node.isRequired,
  height: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
})

export default Scaled
