import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { createEditor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import deepEqual from 'fast-deep-equal'

import letterSpacingFor from '../utils/letterSpacingFor'
import { stateToEditorFormat, editorFormatToState } from 'utils/stateHelpers'
import { withFlatText } from 'utils/slateHelpers'

const SpeechBubble = ({
  color,
  backgroundColor,
  borderTopLeftRadius,
  borderBottomRightRadius,
  fontFamily,
  fontSize,
  lineHeight,
  innerRef,
  onChange,
  margin,
  chunks,
}) => {
  const [editorValue, setEditorValue] = useState(stateToEditorFormat(chunks))
  const [editor] = useState(() => withFlatText(withReact(createEditor())))

  const handleChange = (newEditorValue) => {
    if (isReadOnly() || deepEqual(newEditorValue, editorValue)) {
      return
    }

    setEditorValue(newEditorValue)
    onChange(editorFormatToState(newEditorValue))
  }

  const isReadOnly = () => {
    return !onChange
  }

  const style = {
    backgroundColor,
    color,
    fontFamily,
    fontSize,
    lineHeight: `${lineHeight}px`,
    letterSpacing: `${letterSpacingFor(fontSize)}em`,
    margin,
    borderTopLeftRadius,
    borderBottomRightRadius,
  }

  return (
    <div className="SpeechBubble" style={style} ref={innerRef}>
      <Slate editor={editor} initialValue={editorValue} onChange={handleChange}>
        <Editable
          readOnly={isReadOnly()}
          style={{ whiteSpace: 'pre', outline: 'none' }}
        />
      </Slate>
    </div>
  )
}

SpeechBubble.propTypes = exact({
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  borderTopLeftRadius: PropTypes.string,
  borderBottomRightRadius: PropTypes.string,
  fontFamily: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  lineHeight: PropTypes.number.isRequired,
  innerRef: PropTypes.object,
  onChange: PropTypes.func,
  margin: PropTypes.string,
  chunks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ),
})

export default React.forwardRef((props, ref) => (
  <SpeechBubble innerRef={ref} {...props} />
))
