import React from 'react'

// Due to a bug in Chrome, zoom and transform-scales cause some
// elements to disappear or be incorrectly sized at various zoom/scales
// Including this component inside the zoomed element is a hacky fix.
// Don't know why this fixes the problem.
const ChromeZoomFix = () => {
  return (
    <div
      style={{
        display: 'block',
        minHeight: 0,
        backfaceVisibility: 'hidden',
      }}
    />
  )
}

export default ChromeZoomFix
