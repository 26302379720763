export default (elementsLookup) => {
  return Object.values(elementsLookup).sort((e1, e2) => {
    const z1 = e1.z || 0,
      z2 = e2.z || 0
    if (z1 < z2) {
      return -1
    } else if (z1 > z2) {
      return 1
    } else {
      return 0
    }
  })
}

export const nextLayerZ = (elements) => {
  const elementValues = Object.values(elements)

  if (!elementValues.length) {
    return 0
  }

  const maxElement = elementValues.reduce((max, current) =>
    current.z > max.z ? current : max
  )

  return maxElement.z + 1
}
