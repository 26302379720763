import React, { useLayoutEffect, useRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { convertPercentToPx } from 'utils/unitHelpers'
import useFontsLoaded from 'hooks/useFontsLoaded'
import IndentationWarning from 'components/IndentationWarning'
import FixedSizeOverflowWarning from 'components/FixedSizeOverflowWarning'
import CharacterLengthWarning from 'components/CharacterLengthWarning'
import SpeechBubble from 'components/SpeechBubble'
import TooManyLinesWarning from 'components/TooManyLinesWarning'

const SpeechBubblesElement = ({
  fontFamily,
  isActive,
  onUpdateElement = () => {},
  pageId,
  element: {
    altColor,
    color,
    fontSize,
    lineHeight,
    height,
    id: elementId,
    indentation,
    indentationPct,
    width,
    bottomBubbleChunks,
    topBubbleChunks,
  },
  zoom = 1,
}) => {
  const topBubbleRef = useRef()
  const bottomBubbleRef = useRef()
  const containerRef = useRef()
  const fontsLoaded = useFontsLoaded()

  const [indentationPx, setIndentationPx] = useState(0)

  const isReadOnly = useCallback(() => {
    return !(onUpdateElement && pageId)
  }, [onUpdateElement, pageId])

  const handleChange = useCallback(
    (elementAttrs) => {
      if (isReadOnly()) return

      onUpdateElement(elementId, pageId, elementAttrs)
    },
    [isReadOnly, elementId, onUpdateElement, pageId]
  )

  useLayoutEffect(() => {
    const topBubbleWidth =
      topBubbleRef.current?.getBoundingClientRect().width / zoom

    const usedIndentation = indentationPct
      ? convertPercentToPx(topBubbleWidth, indentationPct)
      : (indentation ?? 0)

    setIndentationPx(usedIndentation)
  }, [
    fontsLoaded,
    fontSize,
    topBubbleChunks,
    indentation,
    indentationPct,
    zoom,
  ])

  const onTopBubbleChange = (chunks) => {
    handleChange({ topBubbleChunks: chunks })
  }

  const onBottomBubbleChange = (chunks) => {
    handleChange({ bottomBubbleChunks: chunks })
  }

  return (
    <div
      className="SpeechBubblesElement"
      data-element-id={elementId}
      style={{
        height,
        width,
      }}
      ref={containerRef}
    >
      {isActive && (
        <>
          <CharacterLengthWarning
            chunks={topBubbleChunks.concat(bottomBubbleChunks)}
            minCharacters={15}
            maxCharacters={60}
          />
          <TooManyLinesWarning
            chunks={topBubbleChunks.concat(bottomBubbleChunks)}
            maxLines={5}
          />
          <IndentationWarning
            allowedPercentages={[20, 40, 60, 80]}
            baseComponentRef={topBubbleRef}
            indentedComponentRef={bottomBubbleRef}
          />
          {/* TODO: remove once we have auto element resizing */}
          <FixedSizeOverflowWarning
            message="speech bubbles are too large"
            containerRef={containerRef}
            allContentRefs={[topBubbleRef, bottomBubbleRef]}
          />
        </>
      )}
      <SpeechBubble
        color={color}
        backgroundColor={altColor}
        borderBottomRightRadius="0"
        chunks={topBubbleChunks}
        fontFamily={fontFamily}
        fontSize={fontSize}
        lineHeight={lineHeight}
        onChange={onTopBubbleChange}
        ref={topBubbleRef}
      />
      <SpeechBubble
        color={altColor}
        backgroundColor={color}
        borderTopLeftRadius="0"
        chunks={bottomBubbleChunks}
        margin={`.4ex 0 0 ${indentationPx}px`}
        fontFamily={fontFamily}
        fontSize={fontSize}
        lineHeight={lineHeight}
        onChange={onBottomBubbleChange}
        ref={bottomBubbleRef}
      />
    </div>
  )
}

SpeechBubblesElement.propTypes = exact({
  fontFamily: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onUpdateElement: PropTypes.func,
  pageId: PropTypes.string,
  zoom: PropTypes.number,
  element: PropTypes.shape({
    altColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,
    lineHeight: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    indentation: PropTypes.number,
    indentationPct: PropTypes.number,
    width: PropTypes.number.isRequired,
    bottomBubbleChunks: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      })
    ),
    topBubbleChunks: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
})

export default SpeechBubblesElement
