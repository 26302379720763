import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

class CardTitle extends PureComponent {
  static propTypes = exact({
    docName: PropTypes.string.isRequired,
  })

  render() {
    const { docName } = this.props

    return <h1 className="CardTitle">{docName}</h1>
  }
}

export default CardTitle
