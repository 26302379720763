import React from 'react'
import { createRoot } from 'react-dom/client'

type Components = Record<string, React.ElementType>

export default function mount(components: Components): void {
  document.addEventListener('DOMContentLoaded', () => {
    const mountPoints = document.querySelectorAll('[data-react-component]')
    mountPoints.forEach((mountPoint) => {
      const { dataset } = mountPoint as HTMLElement
      const componentName = dataset.reactComponent

      if (!componentName) {
        return
      }

      const Component = components[componentName]

      if (!Component) {
        console.warn(
          'WARNING: No component found for: ',
          dataset.reactComponent,
          components
        )
      }

      const root = createRoot(mountPoint)
      const props = JSON.parse(dataset.reactProps as string)
      root.render(<Component {...props} />)
    })
  })
}
