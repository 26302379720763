import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { convertPxToPercent } from 'utils/unitHelpers'
import StyleguideViolationWarning from 'components/StyleguideViolationWarning'

const calculateIndentationPercentage = (
  baseComponentRef,
  indentedComponentRef
) => {
  if (!baseComponentRef.current || !indentedComponentRef.current) return 20

  const { width: baseComponentWidth, left: baseComponentLeft } =
    baseComponentRef.current.getBoundingClientRect()
  const { left: indentedComponentLeft } =
    indentedComponentRef.current.getBoundingClientRect()

  return Math.round(
    convertPxToPercent(
      baseComponentWidth,
      indentedComponentLeft - baseComponentLeft,
      0
    )
  )
}

const IndentationWarning = ({
  allowedPercentages,
  baseComponentRef,
  indentedComponentRef,
}) => {
  const [indentationPercentage, setIndentationPercentage] = useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const indentationPct = calculateIndentationPercentage(
      baseComponentRef,
      indentedComponentRef
    )

    if (indentationPct !== indentationPercentage) {
      setIndentationPercentage(indentationPct)
    }
  })

  const warningMessage = allowedPercentages.some(
    (allowedPercentage) => indentationPercentage === allowedPercentage
  )
    ? null
    : `indentation is ${indentationPercentage}%`
  return <StyleguideViolationWarning message={warningMessage} />
}

IndentationWarning.propTypes = exact({
  allowedPercentages: PropTypes.arrayOf(PropTypes.number).isRequired,
  baseComponentRef: PropTypes.object.isRequired,
  indentedComponentRef: PropTypes.object.isRequired,
})

export default IndentationWarning
