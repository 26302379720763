import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const ElementContainer = ({ children, element, ...rest }) => {
  const transforms = [`translate(${element.left}px, ${element.top}px)`]

  if (element.rotation) {
    transforms.push(`rotate(${element.rotation}deg)`)
  }

  return (
    <div
      className="ElementContainer"
      {...rest}
      style={{
        position: 'absolute',
        transformOrigin: 'top left',
        transform: transforms.join(' '),
      }}
    >
      {children}
    </div>
  )
}

ElementContainer.propTypes = exact({
  children: PropTypes.node.isRequired,
  element: PropTypes.object.isRequired,
  onMouseDown: PropTypes.func,
})

export default ElementContainer
