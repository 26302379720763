import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { Modal } from 'semantic-ui-react'

import CopyToClipboardButton from 'components/CopyToClipboardButton'

class LinkSharer extends PureComponent {
  static propTypes = exact({
    closeLinkSharer: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    exportLinks: PropTypes.array.isRequired,
  })

  constructor(props) {
    super(props)

    this.state = {
      selected: null,
    }

    this.setSelectedLink = this.setSelectedLink.bind(this)
  }

  handleCloseModal = () => {
    this.props.closeLinkSharer()
  }

  setSelectedLink(selection) {
    this.setState({
      selected: selection,
    })
  }

  render() {
    const { isOpen, exportLinks } = this.props

    const { selected } = this.state

    const listLinks = exportLinks.map((linksHash, index) => (
      <div
        className="LinkSharer__link-row"
        key={`creation_export_${linksHash['text']}`}
      >
        <div className="content">
          <i className={`${linksHash['icon']} large icon`}></i>
          <span className="item">{linksHash['text']}</span>
          <CopyToClipboardButton
            link={linksHash['href']}
            index={index}
            selected={selected == index}
            setSelectedLink={this.setSelectedLink.bind(this)}
          />
        </div>
      </div>
    ))

    return (
      <Modal
        className="LinkSharer"
        closeOnDimmerClick={false}
        closeIcon={true}
        onClose={this.handleCloseModal}
        open={isOpen}
        size="tiny"
      >
        <Modal.Header>share link to:</Modal.Header>
        <Modal.Content className="LinkSharer__content">
          <div className="ui list">{listLinks}</div>
        </Modal.Content>
      </Modal>
    )
  }
}

export default LinkSharer
