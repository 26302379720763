import React, { useState } from 'react'
import { Form } from 'semantic-ui-react'

interface BusinessLineWithDivisions {
  readonly name: string
  selectedRole: string | null
}

interface BusinessLinesAndRolesData {
  [index: string]: BusinessLineWithDivisions
}

interface RoleDefinition {
  readonly role: string
  readonly disabled: boolean
}

interface Props {
  initialBusinessLinesAndRoles: BusinessLinesAndRolesData
  roles: RoleDefinition[]
}

const BusinessLineRoleSelector = ({
  initialBusinessLinesAndRoles,
  roles,
}: Props) => {
  const [businessLinesAndRoles, setBusinessLinesAndRoles] = useState(
    initialBusinessLinesAndRoles
  )

  const changeRole = (businessLineId: string, role: string | null) => {
    setBusinessLinesAndRoles({
      ...businessLinesAndRoles,
      [businessLineId]: {
        ...businessLinesAndRoles[businessLineId],
        selectedRole: role,
      },
    })
  }

  const roleSelector = (
    businessLineId: string,
    roles: RoleDefinition[],
    item: BusinessLineWithDivisions
  ) => {
    const options = roles.map((def) => (
      <option key={def.role} value={def.role} disabled={def.disabled}>
        {def.role}
      </option>
    ))

    return (
      <select
        name={`user[business_lines][${businessLineId}][role]`}
        value={item.selectedRole || ''}
        onChange={({ target: { value } }) => changeRole(businessLineId, value)}
      >
        {options}
      </select>
    )
  }

  const inputs = Object.entries(businessLinesAndRoles).map(
    ([businessLineId, item]) => {
      return (
        <tr key={businessLineId}>
          <td>
            <label>{item.name}</label>
          </td>
          <td>{roleSelector(businessLineId, roles, item)}</td>
        </tr>
      )
    }
  )

  return (
    <Form.Field className="BusinessLineRoleSelector">
      <label>business line roles</label>
      <table className="ui celled striped table">
        <tbody>{inputs}</tbody>
      </table>
    </Form.Field>
  )
}

export default BusinessLineRoleSelector
