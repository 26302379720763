import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Icon } from 'semantic-ui-react'

class LinkWithOverlay extends PureComponent {
  static propTypes = exact({
    ariaLabel: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
    overlayText: PropTypes.string.isRequired,
  })

  render() {
    const { ariaLabel, children, href, overlayText } = this.props

    return (
      <a
        className="LinkWithOverlay"
        aria-label={ariaLabel}
        href={href}
        style={{
          cursor: 'pointer',
        }}
      >
        {children}

        <div className="LinkWithOverlay__overlay">
          <div className="LinkWithOverlay__content">
            <Icon name="eye" />
            <div>{overlayText}</div>
          </div>
        </div>
      </a>
    )
  }
}

export default LinkWithOverlay
