import React, { useMemo, useState } from 'react'
import groupObjects from 'utils/groupObjects'
import TemplatePageSizeSelector, {
  CategoryNode,
  PageSize,
} from 'components/TemplatePageSizeSelector'
import { groupedOptionsFromHash } from 'utils/formHelpers'
import { SectionNode } from './EditSections'
import { PageSizeType } from 'EditorApp/types'
import { SelectOption, optionsForSelect } from 'utils/formHelpers'
import { IdType } from 'EditorApp/types'

interface Props {
  readonly pageSizes: Array<PageSize>
  readonly categoryTree: Array<CategoryNode>
  readonly sectionOptions: Record<number, Array<SectionNode>>
  readonly divisionOptions: Array<SelectOption>
  readonly initialSelectedDivisionValue: number
}

const CreateTemplate = ({
  pageSizes,
  categoryTree,
  sectionOptions,
  divisionOptions,
  initialSelectedDivisionValue,
}: Props) => {
  const [platformId, setPlatformId] = useState<IdType | null>(null)
  const [categoryId, setCategoryId] = useState<IdType | null>(null)
  const [pageSize, setPageSize] = useState<PageSizeType>(null)
  const [width, setWidth] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const [sizeSelected, setSizeSelected] = useState(false)
  const [selectedSectionValue, setSelectedSectionValue] = useState('')
  const [selectedDivisionValue, setSelectedDivisionValue] = useState(
    initialSelectedDivisionValue
  )

  const SectionOptions = useMemo(() => {
    if (!sizeSelected || !selectedDivisionValue) return null

    const divisionSectionOptions = sectionOptions[selectedDivisionValue]

    if (!divisionSectionOptions || !divisionSectionOptions.length) return null

    const optionsByGroup = groupObjects(divisionSectionOptions, { by: 'group' })
    return groupedOptionsFromHash(optionsByGroup, 'value', 'text')
  }, [sizeSelected, sectionOptions, selectedDivisionValue])

  const DivisionOptions = useMemo(() => {
    if (divisionOptions.length <= 1) {
      return (
        <input type="hidden" name="division" value={selectedDivisionValue} />
      )
    }

    return (
      <>
        <label htmlFor="division_select">division</label>
        <select
          id="division_select"
          name="division"
          className="CreateTemplate__select"
          onChange={({ target: { value } }) =>
            setSelectedDivisionValue(parseInt(value, 10))
          }
          value={selectedDivisionValue}
        >
          {optionsForSelect(divisionOptions)}
        </select>
      </>
    )
  }, [divisionOptions, selectedDivisionValue])

  return (
    <div className="CreateTemplate">
      <div className="field">
        <label htmlFor="template_name">name</label>
        <input type="text" name="template[name]" id="template_name" required />
      </div>

      <div className="field">{DivisionOptions}</div>

      <TemplatePageSizeSelector
        pageSizes={pageSizes}
        categoryTree={categoryTree}
        platformId={platformId}
        categoryId={categoryId}
        pageSize={pageSize}
        width={width}
        height={height}
        onChangePlatformId={setPlatformId}
        onChangeCategoryId={setCategoryId}
        onChangePageSize={setPageSize}
        onChangeWidth={setWidth}
        onChangeHeight={setHeight}
        onChangeSizeSelected={setSizeSelected}
      />

      {SectionOptions && (
        <div className="field">
          <label htmlFor="section_select">section</label>
          <select
            id="section_select"
            name="template[section_id]"
            className="CreateTemplate__select"
            onChange={({ target: { value } }) => setSelectedSectionValue(value)}
            value={selectedSectionValue ?? ''}
          >
            <option value="">(none)</option>
            {SectionOptions}
          </select>
        </div>
      )}

      {/* pages always set to 1 */}
      <input type="hidden" name="template[pages]" value="1" />

      <div className="actions">
        <button
          disabled={!sizeSelected}
          type="submit"
          className="ui button primary"
        >
          create
        </button>
      </div>
    </div>
  )
}

export default CreateTemplate
