import React from 'react'
import classnames from 'classnames'

export interface CardButtonOption {
  readonly value: string | number
  readonly text?: string
  readonly subText?: string
  readonly size?: string
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  readonly callbackData?: any
}

interface Props {
  readonly options: Array<CardButtonOption>
  readonly id?: string
  readonly inputId?: string
  readonly inputName?: string
  readonly selectedId?: string | number | null
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  readonly onClick: (value: string | number, callbackData?: any) => void
}

const CardButtonSelect = ({
  options,
  id,
  inputId,
  inputName,
  selectedId,
  onClick,
}: Props) => {
  return (
    <>
      <ul role="listbox" className="CardButtonSelect" {...(id ? { id } : {})}>
        {options.map(({ value, text, subText, size, callbackData }) => {
          const selected = value === selectedId

          return (
            <li
              role="option"
              key={value}
              onClick={() => onClick(value, callbackData)}
              className={classnames(
                'CardButtonSelect__item',
                `CardButtonSelect__item--${size || 'button'}`,
                selected && 'CardButtonSelect__item--selected'
              )}
              aria-selected={selected}
            >
              <div>
                {text || value}
                {subText && (
                  <span className="CardButtonSelect__item__subtext">
                    {subText}
                  </span>
                )}
              </div>
            </li>
          )
        })}
      </ul>
      {inputId && inputName && (
        <input
          type="hidden"
          id={inputId}
          name={inputName}
          value={selectedId ?? ''}
        />
      )}
    </>
  )
}

export default CardButtonSelect
