import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Message } from 'semantic-ui-react'

class FlashMessage extends PureComponent {
  static propTypes = exact({
    text: PropTypes.string.isRequired,
    messageType: PropTypes.string.isRequired,
  })

  state = { isVisible: true }

  handleDismiss = () => {
    this.setState({ isVisible: false })
  }

  render() {
    const { isVisible } = this.state
    const { text, messageType } = this.props

    const isSuccess = messageType === 'success'
    const isWarning = messageType === 'warning'
    const isNegative = messageType === 'error' || messageType === 'alert'
    const isInfo = messageType === 'info' || messageType === 'notice'

    return isVisible ? (
      <div className="FlashMessage">
        <Message
          onDismiss={this.handleDismiss}
          success={isSuccess}
          warning={isWarning}
          negative={isNegative}
          info={isInfo}
        >
          <Message.Content>{text}</Message.Content>
        </Message>
      </div>
    ) : null
  }
}

export default FlashMessage
