import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { createEditor, Transforms, Editor } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import {
  fontFamily,
  fontWeight,
  fontStyle,
  lineHeightScale,
  shadowOffsetXScale,
  shadowOffsetYScale,
} from 'constants/tempoTeamHeading'

const withEnsuredEditableRegion = (editor) => {
  const { normalizeNode } = editor

  editor.normalizeNode = ([node, path]) => {
    if (path.length !== 0) {
      return normalizeNode([node, path])
    }

    if (editor.children.length == 1 && Editor.string(editor, [0, 0]) === '') {
      Transforms.insertText(editor, 'Heading...', { at: [0, 0] })
    }

    return normalizeNode([node, path])
  }

  return editor
}

const TempoTeamHeadingElement = ({
  isActive,
  element,
  pageId,
  onUpdateElement,
}) => {
  const toEditorValue = (textLines) => {
    return textLines.map((text) => ({
      type: 'title',
      children: [{ text: text }],
    }))
  }

  const [editorValue, setEditorValue] = useState(
    toEditorValue(element.textLines)
  )

  const [editor] = useState(() =>
    withEnsuredEditableRegion(withReact(createEditor()))
  )

  const handleChange = (newValue) => {
    // Ignore selection changes
    if (editor.operations.every((op) => op.type === 'set_selection')) {
      return
    }

    const textLines = newValue.map(({ children }) => children[0].text)

    setEditorValue(newValue)
    onUpdateElement(element.id, pageId, { textLines })
  }

  const editorStyle = {
    color: element.textColor,
    fontSize: `${element.fontSize}px`,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontStyle: fontStyle,
    lineHeight: lineHeightScale,
  }

  if (element.shadowColor) {
    editorStyle.textShadow = `${shadowOffsetXScale}em ${shadowOffsetYScale}em 0 ${element.shadowColor}`
  }

  const style = {
    width: element.width,
    height: element.height,
    ...(isActive && editorStyle),
  }

  return (
    <div
      className="TempoTeamHeadingElement"
      data-element-id={element.id}
      style={style}
    >
      {isActive ? (
        <Slate
          editor={editor}
          initialValue={editorValue}
          onChange={handleChange}
        >
          <Editable style={{ whiteSpace: 'pre', outline: 'none' }} />
        </Slate>
      ) : (
        <div
          className="TempoTeamHeadingElement__container"
          dangerouslySetInnerHTML={{ __html: element.renderedSvg }}
        />
      )}
    </div>
  )
}

TempoTeamHeadingElement.propTypes = exact({
  isActive: PropTypes.bool,
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    textLines: PropTypes.arrayOf(PropTypes.string).isRequired,
    fontSize: PropTypes.number.isRequired,
    renderedSvg: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    shadowColor: PropTypes.string,
  }),
  pageId: PropTypes.string.isRequired,
  onUpdateElement: PropTypes.func.isRequired,
})

export default TempoTeamHeadingElement
