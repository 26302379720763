import React, { useState } from 'react'
import { Form, Checkbox } from 'semantic-ui-react'
import { union, without } from 'lodash'

interface BusinessLineData {
  readonly id: number
  readonly name: string
}

interface Props {
  inputName: string
  businessLines: BusinessLineData[]
  initialSelectedBusinessLineIds: number[]
}

const BusinessLineSelector = ({
  inputName,
  businessLines,
  initialSelectedBusinessLineIds,
}: Props) => {
  const [businessLineIds, setBusinessLineIds] = useState(
    initialSelectedBusinessLineIds
  )

  const handleChange = (id: number, checked: boolean) => {
    if (checked) {
      setBusinessLineIds(union(businessLineIds, [id]))
    } else {
      setBusinessLineIds(without(businessLineIds, id))
    }
  }

  const inputs = businessLines.map(({ id, name }) => {
    return (
      <tr key={id}>
        <td>
          <label>{name}</label>
        </td>
        <td>
          <Checkbox
            checked={businessLineIds.includes(id)}
            onChange={(e, { checked }) => handleChange(id, !!checked)}
          />
        </td>
      </tr>
    )
  })

  const hiddenInputs = businessLineIds.map((id) => {
    return <input key={id} name={inputName} type="hidden" value={id} />
  })

  return (
    <Form.Field className="BusinessLineSelector">
      <label>business lines</label>
      <table className="ui celled striped table">
        <tbody>{inputs}</tbody>
      </table>

      {hiddenInputs}
    </Form.Field>
  )
}

export default BusinessLineSelector
